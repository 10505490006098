import { Box, Typography } from "@mui/material";
import UploadDocsComponent from "./UploadDocsComponent";
import { useAddStudyStore } from "./useAddStudyStore";

export default function UploadGuides() {
  const { setScreenerSummaryFile, setDiscussionGuideFile, setScreenerGuideFile, setPresentationFile, uploadDocsArray } =
    useAddStudyStore();

  const getFile = (from: number, file: File | null) => {
    switch (from) {
      case 0:
        setScreenerSummaryFile(file);
        break;
      case 1:
        setDiscussionGuideFile(file);
        break;
      case 2:
        setScreenerGuideFile(file);
        break;
      case 3:
        setPresentationFile(file);
        break;
      default:
        break;
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Typography sx={{ fontSize: "12px", fontWeight: "400", color: "#D32F2F" }}>
        * In order to create a new study, you must upload at least a Screener Summary file OR a Presentation file.
      </Typography>
      {uploadDocsArray.map((item) => (
        <UploadDocsComponent
          id={item.id}
          key={item.id}
          header={item.header}
          Icon={item.Icon}
          acceptedFiles={item.accpetedFiles}
          fromAudio={false}
          registerAudioFile={(file) => getFile(item.id, file)}
        />
      ))}
    </Box>
  );
}
