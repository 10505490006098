import { Box, Button, Grid } from "@mui/material";

const BOTTOM_BOX_HEIGHT = 50;

export default function FilterDrawerControls({
  hasActiveFilters,
  onClickReset,
}: {
  hasActiveFilters: boolean;
  onClickReset: () => void;
}) {
  return (
    hasActiveFilters && (
      <Box
        sx={{
          borderTop: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Button variant="text" fullWidth sx={{ height: BOTTOM_BOX_HEIGHT }} onClick={onClickReset}>
              Reset Filters
            </Button>
          </Grid>
        </Grid>
      </Box>
    )
  );
}
