import { SvgIconProps, SvgIconTypeMap, TableCell, TableRow, Typography } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { UUID } from "crypto";
import { atom } from "jotai";
import { SearchBase, SearchFilters } from "../../models/search";

export interface MarketResearchStudy {
  num_respondents: number;
  study_date: string; // "2024-04-23"
  study_key: string; // "venclexta";
  study_title: string; // "Venclexta Combination Qualitative Research";
}

export interface StudyQuestion {
  question_id: string;
  question_title: string;
  responses: number;
}

export interface ChatInteraction {
  source_ids: string[];

  search_object?: SearchFilters;
  timestamp?: number;
  user_chat_session: UUID;
  action_id?: UUID;

  user_id: string;
  user_query?: string;

  answer?: string;
  complete?: boolean;

  evidence?: Evidence[];
}

export type Evidence = {
  complete_answer: string;
  exhaustive_supporting_evidence: string;
  confidence_score: number;
  detailed_confidence: string;
  question: string;
  comprehensive_notes: string;
  respondent_id?: string;
};

//{"type":"chat.source","data":{"record_id":"m8KDlpMBn2rDudtZMaTn"}}
export interface PmrDataSource {
  record_id: string; // m8KDlpMBn2rDudtZMaTn
}

export interface PmrChatRequest {
  search_request: SearchBase;
  user_chat_session?: string | null;
  query: string;
}

export type MarketResearchChatStateType = {
  studyIds: string[];
  invocationType?: "user" | "qna";
  noOfResponses?: number;
  question: string; // free text input state variable
  isLoading: boolean;
  socketStatusMessage: string;
  questionId?: string;
  questionResponse?: StudyQuestion | undefined;
  messages: ChatInteraction[];
  showStudySummary?: boolean;
  respondentId?: string;
  base_filters: Record<string, string[] | undefined>;
  evidence?: Evidence[];
  filterCount: number;
  scope: "list" | "chat";
};

export const MarketResearchStateAtom = atom<MarketResearchChatStateType>({
  studyIds: [],
  invocationType: undefined,
  question: "",
  isLoading: false,
  socketStatusMessage: "",
  messages: [],
  base_filters: {},
  questionId: "",
  questionResponse: undefined,
  showStudySummary: false,
  respondentId: "",
  noOfResponses: undefined,
  filterCount: 0,
  scope: "list",
});

export interface MarketResearchDrawerState {
  open: boolean;
  expanded: string[];
}

export const MarketResearchDrawerStateAtom = atom<MarketResearchDrawerState>({
  open: false,
  expanded: [],
});

export const FocusedInteraction = atom<ChatInteraction | undefined>(undefined);

export type TMRTranscripts = {
  respondent_id: number;
  persona: string;
  target_priority?: string;
  specialty: string;
  practice_segment: string;
  heir: string;
  transcript: string;
  transcript_summary: string;
};

export type TStudySummary = {
  summary: string;
};

export type TPMRChat = {
  summary: string;
  question: string;
  evidences: TEvidence[];
};

export type TEvidence = {
  complete_answer: string;
  exhaustive_supporting_evidence: string;
  confidence: number;
  comprehensive_notes: string;
  confidence_details: string;
  evidence: string;
};

export type TInterview = {
  fromStudySummary: boolean;
  respondantIds?: string[];
} & Partial<MarketResearchStudy>;

export type TQAChatEvidence = {
  evidence: Partial<Evidence>;
  expanded: boolean;
  toggleExpand: (event: React.MouseEvent<HTMLElement>) => void;
};

export interface PMREvidencesProps {
  evidences: Evidence[];
}

export type TAudioTranscript = {
  file: File | null;
  audioId: string;
  respondentIds: string[];
};

export type TAddStudyState = {
  currentStep: number;
  steps: string[];
  isLoading: boolean;
  openEditorModal: boolean;

  // Form fields:
  studyId: number;
  studyType: string;
  vendorName: string;
  studyName: string;
  therapeuticArea: string;
  vendorNameArray: string[] | undefined;
  therapeuticAreaArray: string[] | undefined;
  productArray: string[] | undefined;
  editorsArray?: TEditorsListResponse[] | undefined;
  brand: string;
  startDate: string;
  endDate: string;
  screenerSummaryFile: File | null;
  discussionGuideFile: File | null;
  screenerGuideFile: File | null;
  presentationFile: File | null;
  audioFiles: TAudioTranscript[] | null;

  // We'll compute isNextDisabled dynamically rather than storing it
};

export const AddStudyStateAtom = atom<TAddStudyState>({
  currentStep: 0,
  steps: ["Enter Study Details", "Upload Guides", "Upload Audio"],
  isLoading: false,
  vendorNameArray: undefined,
  therapeuticAreaArray: undefined,
  productArray: undefined,
  editorsArray: undefined,
  openEditorModal: false,

  // Form fields:
  studyId: 0,
  studyType: "",
  vendorName: "",
  studyName: "",
  therapeuticArea: "",
  brand: "",
  startDate: "",
  endDate: "",
  screenerSummaryFile: null,
  discussionGuideFile: null,
  screenerGuideFile: null,
  presentationFile: null,
  audioFiles: null,
});

export type TDropdownComponent = {
  array: string[];
  label: string;
  value: string;
  onChange: (e: any) => void;
};

export type TUploadDocs = {
  id: number;
  header?: string;
  respondentId?: string;
  audioId?: string;
  Icon?: OverridableComponent<SvgIconTypeMap & any>;
  fromAudio?: boolean;
  acceptedFiles?: string;
  fromEdit?: boolean;
  registerAudioFile: (file: File | null) => void;
};

export type TAddStudyResponse = {
  study_id: number;
  vendor_name: string;
  study_name: string;
  therapeutic_area: string;
  product: string;
  study_status: string;
  start_date: string;
  end_date: string;
};

export type TS3UploadResponse = {
  file_id: UUID;
  user_id: string;
  s3_file_url: string;
  s3_filename: string;
  file_name: string;
  file_status: string;
};

export type TStudyListResponse = {
  list_type: string;
  items: string[];
};

export interface TRespondentIdsResponse {
  audio_ids: AudioId[];
}

export interface AudioId {
  audio_file_id: string;
  respondent_ids: string[];
}

export type TTableCellProps = React.ComponentProps<typeof TableCell> & {
  width?: string;
  fontSize?: string;
  children?: React.ReactNode;
};

export type TTableRowProps = React.ComponentProps<typeof TableRow> & {
  width?: string;
  children: React.ReactNode;
};

export type TEditAudioFileUploadProps = {
  id: number;
  study_name: string;
  uploaded_date: string;
  uploaded_by: string;
  status: string;
  audio_id: string;
  respondent_ids: string[];
};

export type TMenuButtonType = {
  label: string;
  Icon: React.ComponentType<SvgIconProps>;
  iconProps?: SvgIconProps;
  labelProps?: React.ComponentProps<typeof Typography>;
};

export type TButtonGroupType = {
  label: string;
  Icon: React.ComponentType<SvgIconProps>;
  buttonRef?: React.RefObject<HTMLButtonElement>;
  handleOnClick: (e) => void;
};

export type TAudioMenuType = {
  anchorEl: null | HTMLElement;
  open: boolean;
  handleClose: () => void;
};

export type TManageStudiesResponse = {
  study_id: UUID;
  vendor_name: string;
  study_name: string;
  therapeutic_area: string;
  product: string;
  study_status: "CREATED" | "GATHERING_INPUTS" | "ALL_AUDIO_TRANSCRIBED" | "PROCESSING" | "PUBLISHED";
  start_date: string;
  end_date: string;

  created_ts: string; // "2025-03-23T21:49:06.266983";
  updated_ts: string; // "2025-03-31T21:44:26.366426";
  editors?: string[];
  owning_user_id: UUID;
  owning_user_auth_id: "leibniz3";
  study_type: string;
};

export type TManageStudiesStore = {
  page: number;
  byEditors: boolean;
  studies: TManageStudiesResponse[];
};

export const TManageStudiesStore = atom<TManageStudiesStore>({
  page: 1,
  byEditors: false,
  studies: [],
});

export type TEditorsListResponse = {
  user_guid: string;
  auth_user_id: string;
  auth_user_email: string;
};

export interface ManageStudiesState {
  studyCounts: Record<string, number>;
  isAPILoading: boolean;
  studyId?: string;
  currentIndex?: number;
}

export type TCompleteSaveButtonType = {
  anchorEl: null | HTMLElement;
  open: boolean;
  isSaveDisabled: boolean;
  isCompleteDisabled: boolean;
  // handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleClose: () => void;
  handleSaveOnlyClick: () => void;
  handleCompleteClick: () => void;
};
