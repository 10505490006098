import dayjs from "dayjs";

export function hasDatePassed(date: string) {
  const startDate = dayjs(date, "YYYY-MM-DD");

  const now = dayjs();

  // Calculate the difference in days
  const diffInDays = startDate.diff(now, "day");

  // Return true if the difference is greater than or equal to the given number of days
  return diffInDays >= 0;
}

export function formatToMMDDYYYY(rawValue) {
  // 1) Remove all non-digits
  const onlyDigits = rawValue.replace(/\D/g, "");

  // 2) Build up the formatted output in stages
  //    - First 2 digits => MM
  //    - Next 2 digits => DD
  //    - Last 4 digits => YYYY

  if (onlyDigits.length <= 2) {
    // 0..2 digits => just show them
    return onlyDigits;
  } else if (onlyDigits.length <= 4) {
    // 3..4 digits => MM - DD (partial if only 3)
    return onlyDigits.slice(0, 2) + "-" + onlyDigits.slice(2);
  } else if (onlyDigits.length <= 8) {
    // 5..8 digits => MM - DD - YYYY (partial if < 8)
    return onlyDigits.slice(0, 2) + "-" + onlyDigits.slice(2, 4) + "-" + onlyDigits.slice(4);
  } else {
    // More than 8 digits => ignore extras
    const clipped = onlyDigits.slice(0, 8);
    return clipped.slice(0, 2) + "-" + clipped.slice(2, 4) + "-" + clipped.slice(4);
  }
}
