import { EditNoteOutlined, SpatialAudioOffSharp } from "@mui/icons-material";
import { Badge, Box, Button, Card, CircularProgress, Divider, Typography } from "@mui/material";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { TOP_BAR_HEIGHT } from "../../../pages/AppLayout";
import { useAddStudyStore } from "../AddStudy/useAddStudyStore";
import EditAudioFileUpload from "./EditAudioFileUpload";
import EditStudyOverview from "./EditStudyOverview";
import { useManageStudiesStore } from "./useManageStudiesStore";

export default function EditStudy() {
  const params = useParams();
  const { resetState, navigate } = useAddStudyStore();
  const {
    completeStudy,
    setStudyId,
    ManageStudies,
    resetState: resetManageStudies,
    setCurrentIndex,
  } = useManageStudiesStore();

  useEffect(() => {
    if (params.studyId) {
      setStudyId(params.studyId);
    }
  }, [params]);

  useEffect(() => {
    return () => {
      resetState();
      resetManageStudies();
    };
  }, []);

  return (
    <Box
      sx={{
        marginTop: `${TOP_BAR_HEIGHT}px`,
        paddingX: "10rem",
        paddingY: "2rem",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        height: `calc(100vh - ${TOP_BAR_HEIGHT}px)`,
      }}
    >
      <Box
        sx={{
          flex: "0 0 auto",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "2rem",
          display: "flex",
        }}
      >
        <Typography sx={{ fontSize: "24px", color: "#004677", fontWeight: "400" }}>Edit Study</Typography>
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Button
            variant="outlined"
            color="primary"
            size="medium"
            sx={{ color: "#003087" }}
            onClick={() => navigate(-1)}
          >
            CANCEL
          </Button>
          <Button variant="contained" color="primary" size="medium" sx={{ color: "white", backgroundColor: "#003087" }}>
            SAVE
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            sx={{ color: "white", backgroundColor: "#003087", minWidth: "150px" }}
            onClick={completeStudy}
          >
            {ManageStudies.isAPILoading ? (
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                <CircularProgress size={24} sx={{ color: "white" }} />{" "}
              </Box>
            ) : (
              "COMPLETE STUDY"
            )}
          </Button>
        </Box>
      </Box>

      <Card
        sx={{
          width: "100%",
          padding: "1rem",
          borderRadius: "10px",
          height: "100%",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          scrollbarWidth: "none",
          "-ms-overflow-style": "none",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="h6" sx={{ fontSize: "16px", fontWeight: "700", color: "#13294B" }}>
              Vendor Name | Study Name
            </Typography>
            <Typography variant="h6" sx={{ fontSize: "12px", fontWeight: "400", color: "#13294B" }}>
              Created XX-XX-2025 by asmith@gene.com
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
            <Box
              onClick={() => {
                setCurrentIndex(0);
              }}
              sx={{
                display: "flex",
                backgroundColor: ManageStudies.currentIndex === 0 ? "rgba(0, 48, 135, 0.08)" : "transparent",
                borderRadius: "20%",
                justifyContent: "center",
                alignItems: "center",
                padding: "0.3rem",
                cursor: "pointer",
              }}
            >
              <EditNoteOutlined />
            </Box>
            <Box
              sx={{
                display: "flex",
                backgroundColor: ManageStudies.currentIndex === 1 ? "rgba(0, 48, 135, 0.08)" : "transparent",
                borderRadius: "20%",
                justifyContent: "center",
                alignItems: "center",
                padding: "0.3rem",
                cursor: "pointer",
              }}
              onClick={() => {
                setCurrentIndex(1);
              }}
            >
              <Badge
                overlap="circular"
                badgeContent=""
                variant="dot"
                color="default"
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                sx={{
                  backgroundColor: " rgba(0, 48, 135, 0.08))",
                  "& .MuiBadge-dot": { minWidth: "8px", height: "8px", right: "10px", top: "4px" },
                }}
              >
                <SpatialAudioOffSharp />
              </Badge>
            </Box>
          </Box>
        </Box>
        <Divider sx={{ my: "1rem" }}></Divider>
        {ManageStudies.currentIndex === 0 && <EditStudyOverview />}
        {ManageStudies.currentIndex === 1 &&
          [
            { file: null, audioId: null, respondentIds: ["1"], status: "Completed" },
            { file: null, audioId: null, respondentIds: ["2"], status: "Not Uploaded" },
          ].map((data, index) => (
            <EditAudioFileUpload
              id={index}
              key={index}
              respondentId={data.respondentIds[0]}
              registerAudioFile={(file) => console.log(file)}
              acceptedFiles=".mp3,.wav,.flac,.m4a,.aac,.wma,.ogg,.oga,.webm,.amr,.3gp"
            />
          ))}
      </Card>
    </Box>
  );
}
