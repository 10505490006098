import { ArrowDropDown } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Typography } from "@mui/material";
import { TButtonGroupType } from "../models";

const CustomButtonGroup = ({ label, Icon, buttonRef, handleOnClick }: TButtonGroupType) => {
  return (
    <ButtonGroup
      color="inherit"
      orientation="horizontal"
      variant="contained"
      sx={{ justifyItems: "center", alignItems: "center", backgroundColor: "#f7f8f8", height: "34px" }}
    >
      <Box sx={{ display: "flex", px: "10px", py: "5px", gap: 1, borderRight: 1, borderColor: "#e5e5ef" }}>
        <Icon sx={{ fontSize: "20px", color: "#003087" }} />
        <Typography sx={{ fontSize: "14px", fontWeight: "400", color: "#13294B" }}>{label}</Typography>
      </Box>
      <Button
        variant="text"
        color="primary"
        size="medium"
        ref={buttonRef}
        sx={{ color: "#003087", height: "40px" }}
        id="basic-button"
        onClick={handleOnClick}
      >
        <ArrowDropDown />
      </Button>
    </ButtonGroup>
  );
};

export default CustomButtonGroup;
