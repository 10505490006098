import { AddSharp, ArrowBack, Delete } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  Pagination,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { useEffect } from "react";
import { BodyPanel, MainPanel } from "../Layout/Panels";
import { TTableCellProps } from "../models";
import useMarketResearchStore from "../useMarketResearchStore";
import StatusIndicator from "./StatusIndicator";
import StudiesTab from "./StudiesTab";
import { useManageStudiesStore } from "./useManageStudiesStore";
import dayjs from "dayjs";

export default function ManageStudies() {
  const { toggleFilterDrawer, navigate } = useMarketResearchStore();
  const { data, isLoading, StudyListParams, setPage, getViewRecordCount, refetch } = useManageStudiesStore();

  const studyCount = getViewRecordCount(StudyListParams.permission_level);

  useEffect(() => {
    refetch();
  }, []);

  return (
    <MainPanel>
      <BodyPanel sx={{ mx: "4em" }}>
        <Box
          sx={{
            flex: "0 0 auto",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "2rem",
            display: "flex",
          }}
        >
          <Typography variant="h5">Manage Studies</Typography>
          <Box>
            <Button
              onClick={() => {
                navigate("/market-research");
              }}
              variant="text"
              color="primary"
              size="medium"
              startIcon={<ArrowBack sx={{ color: "#003087" }} />}
              sx={{ color: "#003087" }}
            >
              ALL STUDIES
            </Button>
            <Button
              onClick={() => {
                toggleFilterDrawer(false);
                navigate("/market-research/add-study");
              }}
              variant="text"
              color="primary"
              size="medium"
              startIcon={<AddSharp sx={{ color: "#003087" }} />}
              sx={{ color: "#003087" }}
            >
              ADD STUDY
            </Button>
          </Box>
        </Box>

        <Card
          sx={{
            width: "100%",
            padding: "1rem",
            borderRadius: "10px",
          }}
        >
          <Box>
            <Box style={{ display: "flex" }}>
              <Box>
                <StudiesTab />
              </Box>
              <Box style={{ flex: 1, textAlign: "right" }}>
                {isLoading ? <CircularProgress size={20} style={{ margin: "6px 10px 0 0" }} /> : undefined}
              </Box>
            </Box>

            <TableContainer sx={{ my: "1rem", background: "rgba(239, 240, 240, 0.3)" }}>
              <Table size="small" sx={{ color: "#13294B" }}>
                <TableHead>
                  <TableRow>
                    <TableCell width="22%">Study Name</TableCell>
                    <TableCell>Fielded</TableCell>
                    <TableCell>Created</TableCell>
                    <TableCell>Therapeutic Area</TableCell>
                    <TableCell>Product</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading
                    ? [...Array(10)].map((row, i) => (
                        <TableRow key={i} sx={{ height: "53px" }}>
                          {[...Array(7)].map((col, j) => (
                            <CustomTableCell key={i + "-" + j}>
                              <Skeleton variant="text" />
                            </CustomTableCell>
                          ))}
                        </TableRow>
                      ))
                    : data?.studies?.map((study) => (
                        <TableRow key={study.study_id}>
                          <CustomTableCell width="22%" style={{ textWrap: "nowrap" }}>
                            {study.study_name}
                          </CustomTableCell>
                          <CustomTableCell>{dayjs(study.start_date).format("MM-DD-YYYY")}</CustomTableCell>
                          <CustomTableCell>
                            <p style={{ textWrap: "nowrap", margin: 0 }}>
                              {dayjs(study.created_ts).format("MM-DD-YYYY")} by
                            </p>
                            <p style={{ textWrap: "nowrap", margin: 0 }}>{study.owning_user_auth_id}</p>
                          </CustomTableCell>
                          <CustomTableCell>{study.therapeutic_area}</CustomTableCell>
                          <CustomTableCell>{study.product}</CustomTableCell>
                          <CustomTableCell>
                            <StatusIndicator status={study.study_status} />
                          </CustomTableCell>
                          <CustomTableCell style={{ textWrap: "nowrap" }}>
                            <IconButton
                              color="primary"
                              onClick={() => navigate(`/market-research/edit-study/${study.study_id}`)}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton color="primary">
                              <Delete />
                            </IconButton>
                          </CustomTableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>

            {/* Pagination */}
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Pagination
                showFirstButton
                showLastButton
                count={Math.ceil((studyCount ?? 1) / StudyListParams.page_size)}
                page={StudyListParams.page}
                onChange={(e, page) => setPage(page)}
              />
            </Box>
          </Box>
        </Card>
      </BodyPanel>
    </MainPanel>
  );
}

const CustomTableCell = (props: TTableCellProps) => {
  return (
    <TableCell
      {...props}
      width={props.width || "auto"}
      sx={{
        fontSize: props.fontSize || "12px",
        color: "#13294B",
        overflow: "hidden",
        ...props.sx,
      }}
    />
  );
};
