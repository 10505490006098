import { Box, CircularProgress, Typography } from "@mui/material";
import UploadDocsComponent from "./UploadDocsComponent";
import { useAddStudyStore } from "./useAddStudyStore";

export default function UploadAudio() {
  const { addAudioFile, respondentIdsArray, isRespondentIdsLoading, screenerSummaryFile } = useAddStudyStore();

  const registerAudioFile = (file: File | null, audio_file_id: string, respondent_ids: string[]) => {
    addAudioFile({ file, audioId: audio_file_id, respondentIds: respondent_ids });
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      {!screenerSummaryFile ? (
        <Typography variant="h6" sx={{ fontSize: "12px", fontWeight: "400", color: "#D32F2F" }}>
          * In order to add audio files, you must upload a Screener Summary in the previous step.
        </Typography>
      ) : isRespondentIdsLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        respondentIdsArray?.audio_ids.map(({ audio_file_id, respondent_ids }, index) => (
          <UploadDocsComponent
            id={index}
            key={index}
            header={index === 0 ? "*Audio Transcripts" : undefined}
            fromAudio={true}
            audioId={audio_file_id}
            registerAudioFile={(file) => registerAudioFile(file, audio_file_id, respondent_ids)}
            respondentId={respondent_ids.at(0)}
            acceptedFiles=".mp3,.wav,.flac,.m4a,.aac,.wma,.ogg,.oga,.webm,.amr,.3gp,.mp4"
          />
        ))
      )}
    </Box>
  );
}
