import { Box } from "@mui/material";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import FilterDrawer from "../components/MarketResearch/Filter/FilterDrawer";
import useMarketResearchMessageHandler from "../components/MarketResearch/useMarketResearchMessageHandler";
import { DeepChatSocketContext } from "../contexts/DeepChatSocketContext";
import useMarketResearchSocket from "../store/market-research/useMarketResearchSocket";
import useMarketResearchStore from "../components/MarketResearch/useMarketResearchStore";

export default function MarketResearchPage() {
  const { lastJsonMessage, readyState, sendJsonMessage } = useMarketResearchSocket();
  const { processSocketMessage } = useMarketResearchMessageHandler();
  const { drawer } = useMarketResearchStore();

  // Hook into the Websocket stream here to collect chat responses (experimental pattern)
  useEffect(() => {
    processSocketMessage(lastJsonMessage);
  }, [lastJsonMessage]);

  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, height: "100vh", paddingLeft: drawer.open ? "300px" : undefined, transition: "padding 225ms" }}
    >
      <FilterDrawer />
      <DeepChatSocketContext.Provider value={{ lastJsonMessage, sendJsonMessage, readyState }}>
        <Outlet />
      </DeepChatSocketContext.Provider>
    </Box>
  );
}
