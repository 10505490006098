import { Box, Button, Card, CircularProgress, Typography } from "@mui/material";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import { AutoAwesomeOutlined } from "@mui/icons-material";
import { BodyPanel, MainPanel, RightPanel } from "../Layout/Panels";
import useMarketResearchStore from "../useMarketResearchStore";
import InterviewComponent from "../ViewStudy/InterviewComponent";
import { formatResponseText } from "../ViewTransript/ViewTranscript";

export default function StudySummary() {
  const params = useParams();

  const { studyIds, setStudyId, getStudy, isStudySummaryLoading, studySummaryResponse, navigate, respondentIdsArray } =
    useMarketResearchStore();

  // On mount/update, sync the studyId
  useEffect(() => {
    if (params.studyId && params.studyId !== studyIds.at(0)) {
      setStudyId(params.studyId);
    }
  }, [params, studyIds, setStudyId]);

  // Get the study details
  const studyId = studyIds.length == 1 ? studyIds.at(0) : undefined;
  const study = studyId ? getStudy(studyId) : undefined;

  return (
    <MainPanel>
      {/* LEFT COLUMN */}
      <BodyPanel>
        {/* HEADER (Non-Scrolling) */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexShrink: 0, // Don’t shrink the header
            mb: 2,
          }}
        >
          <Typography variant="h5" sx={{ color: "#004677" }}>
            {study?.study_title}
          </Typography>
          <Link to={"/market-research"} style={{ textDecoration: "none", color: "#003087" }}>
            <Button color="primary" sx={{ color: "#003087" }}>
              CHANGE STUDY
            </Button>
          </Link>
        </Box>

        {/* SCROLLABLE SUMMARY AREA */}
        <Box
          sx={{
            // The magic for scrolling in a flex column:
            // 1) flex: 1 to take remaining space
            // 2) minHeight: 0 so the box can shrink to let content scroll
            flex: 1,
            minHeight: 0,
            overflowY: "auto",
            // Gap or margin if needed
          }}
        >
          {/* "Deepsense Summary" heading */}
          <Box
            sx={{
              display: "flex",
              mb: 2,
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <AutoAwesomeOutlined
                sx={{
                  fill: "#13294B",
                  verticalAlign: "sub",
                  marginRight: "0.5em",
                }}
              />
              <Typography variant="h6" color={"#13294B"} display="inline-block">
                Deepsense Summary
              </Typography>
            </Box>
            {/* <Feedback /> */}
          </Box>

          {/* Card that wraps the summary text */}
          <Card sx={{ mb: 2 }}>
            <Box sx={{ padding: "1rem" }}>
              {isStudySummaryLoading ? (
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                  <CircularProgress />
                </Box>
              ) : (
                formatResponseText(studySummaryResponse?.summary ?? "")
              )}
            </Box>
          </Card>
        </Box>
      </BodyPanel>

      {/* RIGHT COLUMN */}
      <RightPanel>
        <Box sx={{ height: "100%", overflow: "hidden" }}>
          <InterviewComponent {...study} fromStudySummary={true} respondantIds={respondentIdsArray} />
        </Box>
        <Button
          variant="outlined"
          sx={{ color: "#003087", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
          onClick={() => {
            navigate(-1);
          }}
        >
          BACK TO STUDY
        </Button>
      </RightPanel>
    </MainPanel>
  );
}
