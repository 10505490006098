import { ChatBubbleOutline, DataObjectOutlined, SlideshowOutlined, TableChartOutlined } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import { AxiosError, isAxiosError } from "axios";
import { UUID } from "crypto";
import dayjs from "dayjs";
import { useAtom } from "jotai";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  callAddStudyAPI,
  callCompleteStudyAPI,
  callGetAddStudyListAPI,
  callGetEditorsList,
  callGetRespondentIds,
  callTriggerAudioProcessing,
  callVerifyScreenSummaryFile,
  getPreSignedS3UrlAddStudy,
  uploadDocumentToS3,
} from "../../../api/dataSources";
import emitSnackbar from "../../../emitSnackbar";
import useUserContext from "../../Authorization/useUserContext";
import { AddStudyStateAtom, TAudioTranscript, TEditorsListResponse } from "../models";

export const useAddStudyStore = () => {
  const user = useUserContext();
  const navigate = useNavigate();
  const [state, setState] = useAtom(AddStudyStateAtom);
  const array = [
    { id: 0, header: "Screener Summary", Icon: TableChartOutlined, accpetedFiles: ".csv,.xml" },
    { id: 2, header: "Screener Guide", Icon: DataObjectOutlined, accpetedFiles: ".pdf,.doc,.docx" },
    { id: 1, header: "Discussion Guide", Icon: ChatBubbleOutline, accpetedFiles: ".pdf,.doc,.docx" },
    { id: 3, header: "Presentation", Icon: SlideshowOutlined, accpetedFiles: ".ppt,.pptx" },
  ];

  // -- Update helpers --
  const setStudyType = (value: string) => setState((prev) => ({ ...prev, studyType: value }));
  const setVendorArray = (value: string[]) => setState((prev) => ({ ...prev, vendorNameArray: value }));
  const setTherapeuticAreaArray = (value: string[]) => setState((prev) => ({ ...prev, therapeuticAreaArray: value }));
  const setProductArray = (value: string[]) => setState((prev) => ({ ...prev, productArray: value }));
  const setVendorName = (value: string) => setState((prev) => ({ ...prev, vendorName: value }));
  const setStudyName = (value: string) => setState((prev) => ({ ...prev, studyName: value }));
  const setTherapeuticArea = (value: string) => setState((prev) => ({ ...prev, therapeuticArea: value }));
  const setBrand = (value: string) => setState((prev) => ({ ...prev, brand: value }));
  const setStartDate = (value: string) => setState((prev) => ({ ...prev, startDate: value }));
  const setEndDate = (value: string) => {
    const startDate = dayjs(state.startDate);
    const endDate = dayjs(value);
    if (endDate.isAfter(startDate) || endDate.isSame(startDate)) {
      setState((prev) => ({ ...prev, endDate: value }));
    } else {
      emitSnackbar("End date should be after start date", "error");
    }
  };
  const setScreenerSummaryFile = (value: File | null) => setState((prev) => ({ ...prev, screenerSummaryFile: value }));
  const setDiscussionGuideFile = (value: File | null) => {
    setState((prev) => ({ ...prev, discussionGuideFile: value }));
  };
  const setScreenerGuideFile = (value: File | null) => {
    setState((prev) => ({ ...prev, screenerGuideFile: value }));
  };
  const setPresentationFile = (value: File | null) => setState((prev) => ({ ...prev, presentationFile: value }));
  const addAudioFile = (data: TAudioTranscript) => {
    setState((prev) => ({
      ...prev,
      audioFiles:
        data.file === null
          ? (prev.audioFiles ?? []).filter((audio) => audio.audioId !== data.audioId)
          : [...(prev.audioFiles ?? []), data],
    }));
  };

  const setEditorsArray = (editorsArray: TEditorsListResponse[]) => setState((prev) => ({ ...prev, editorsArray }));
  const toggleEditorModal = (value: boolean) => setState((prev) => ({ ...prev, openEditorModal: value }));

  const checkForDuplicate = (file: File | null) => {
    if (state.discussionGuideFile?.name === file?.name) {
      return true;
    }

    if (state.screenerGuideFile?.name === file?.name) {
      return true;
    }

    return false;
  };

  const isNextDisabled = useMemo(() => {
    if (state.currentStep === 0) {
      return (
        !state.studyType ||
        !state.vendorName ||
        !state.studyName ||
        !state.therapeuticArea ||
        !state.brand ||
        !state.startDate
      );
    }
    if (state.currentStep === 1) {
      return !(state.screenerSummaryFile || state.discussionGuideFile) && !state.presentationFile;
    }
    if (state.currentStep === 2) {
      if (!state.screenerSummaryFile) {
        return false;
      } else {
        return state.audioFiles && state.audioFiles.length === 0;
      }
    }
    return true; // Disable button by default when currentStep changes
  }, [
    state.studyType,
    state.vendorName,
    state.studyName,
    state.therapeuticArea,
    state.brand,
    state.startDate,
    state.screenerSummaryFile,
    state.discussionGuideFile,
    state.screenerGuideFile,
    state.presentationFile,
    state.currentStep,
    state.audioFiles,
  ]);

  const resetState = () => {
    // If you want to reset everything:
    setState((prev) => ({
      ...prev,
      currentStep: 0,
      vendorName: "",
      studyName: "",
      therapeuticArea: "",
      brand: "",
      startDate: "",
      endDate: "",
      screenerSummaryFile: null,
      discussionGuideFile: null,
      screenerGuideFile: null,
      presentationFile: null,
      audioFiles: null,
      studyType: "",
      editorsArray: [],
    }));
  };

  const sanitizeString = (str: string) => {
    if (str.includes("*")) {
      str = str.replace(/\*/g, "");
    }

    if (str.includes("-")) {
      str = str.replace(/-/g, "");
    }
    if (str.includes(" ")) {
      str = str.replace(/ /g, "_");
    }
    return str.toLowerCase();
  };

  const getFileName = (index: number) => {
    switch (index) {
      case 0:
        return (
          sanitizeString(state.vendorName) +
          "_" +
          sanitizeString(state.studyName) +
          "_" +
          "screener_summary" +
          "_" +
          sanitizeString(state.startDate)
        );

      case 1:
        return (
          sanitizeString(state.vendorName) +
          "_" +
          sanitizeString(state.studyName) +
          "_" +
          "discussion_guide" +
          "_" +
          sanitizeString(state.startDate)
        );

      case 2:
        return (
          sanitizeString(state.vendorName) +
          "_" +
          sanitizeString(state.studyName) +
          "_" +
          "screener_guide" +
          "_" +
          sanitizeString(state.startDate)
        );

      case 3:
        return (
          sanitizeString(state.vendorName) +
          "_" +
          sanitizeString(state.studyName) +
          "_" +
          "presentation" +
          "_" +
          sanitizeString(state.startDate)
        );

      default:
        break;
    }
  };

  const getFileType = (index: number) => {
    switch (index) {
      case 0:
        return "screener_summary";

      case 1:
        return "discussion_guide";

      case 2:
        return "screener_guide";

      case 3:
        return "study_summary";

      default:
        break;
    }
  };

  const getFileExtension = (fileName: string) => fileName.split(".").pop();

  const uploadGuidesToS3 = async () => {
    const filesArray = [
      { file: state.screenerSummaryFile, index: 0 },
      { file: state.discussionGuideFile, index: 1 },
      { file: state.screenerGuideFile, index: 2 },
      { file: state.presentationFile, index: 3 },
    ];

    setState((prev) => ({ ...prev, isLoading: true }));

    try {
      const uploadPromises: Promise<{ index: number; url?: string; error?: string; status?: string }>[] = filesArray
        .filter(({ file }) => file !== null && file !== undefined)
        .map(async ({ file, index }, pos) => {
          if (!file) return { index, error: "No file provided" };

          // Get pre-signed URL
          const response = await getPreSignedS3UrlAddStudy(user, {
            file_name: `${getFileName(index)}.${getFileExtension(file.name)}`,
            file_type: getFileType(index) ?? "",
            study_id: state.studyId,
            audio_file_id: null,
            respondent_ids: null,
          });

          if (response.s3_file_url) {
            // Upload file to S3
            const uploadResponse = await uploadDocumentToS3(response.s3_file_url, file);
            if (uploadResponse) {
              return { index, url: response.s3_file_url, status: "success" };
            }

            return { index };
          }

          return { index, error: "Failed to get S3 URL", status: "error" };
        });

      const results = await Promise.all(uploadPromises);
      const allSuccess = results.length > 0 && results.every((result) => result.status === "success");
      if (allSuccess) {
        if (state.screenerSummaryFile !== null) {
          const verifyScreenFileResponse = await callVerifyScreenSummaryFile(user, state.studyId);
          if (verifyScreenFileResponse) {
            setState((prev) => ({ ...prev, currentStep: 2 }));
          } else {
            emitSnackbar(
              "The uploaded screener summary is not valid. Please click on the 'See template' link next to the screener summary upload box. ",
              "error",
            );
          }
        } else {
          setState((prev) => ({ ...prev, currentStep: 2 }));
        }
      }
    } finally {
      setState((prev) => ({ ...prev, isLoading: false }));
    }
  };

  const uploadAudioFilesToS3 = async () => {
    setState((prev) => ({ ...prev, isLoading: true }));

    try {
      const uploadPromises: Promise<{
        file_id?: UUID;
        file_name?: string;
        url?: string;
        error?: string;
        status?: string;
      }>[] = state.audioFiles!.map(async ({ file, audioId, respondentIds }) => {
        if (!file) return { audio_file_id: audioId, error: "No file provided" };

        // Get pre-signed URL
        const response = await getPreSignedS3UrlAddStudy(user, {
          file_name: file.name,
          file_type: "audio",
          study_id: state.studyId,
          audio_file_id: audioId,
          respondent_ids: respondentIds,
        });

        if (response.s3_file_url) {
          // Upload file to S3
          await uploadDocumentToS3(response.s3_file_url, file);
          return {
            file_id: response.file_id,
            file_name: response.file_name,
            url: response.s3_file_url,
            status: "success",
          };
        }
        return { error: "Failed to get S3 URL" };
      });

      const results = await Promise.all(uploadPromises);

      const allSuccess = results.every((result) => result.status === "success");
      if (allSuccess) {
        triggerAudioProcessing(
          results.map((result) => ({
            file_id: result.file_id,
            file_name: result.file_name,
          })),
        );
      } else {
        emitSnackbar("Error uploading files", "error");
        setState((prev) => ({ ...prev, isLoading: false, currentStep: 2 }));
      }
    } catch (err) {
      console.error("Error uploading files", err);
      setState((prev) => ({ ...prev, isLoading: false, currentStep: 2 }));
    }
  };

  const triggerAudioProcessing = async (json: Record<string, any>) => {
    try {
      const response = await callTriggerAudioProcessing(user, "" + state.studyId, {
        file_details: json,
      });
      if (response) {
        navigate(-1);
        emitSnackbar("Study has been successfully added", "success");
      }
    } catch (error) {
      console.error("Error uploading files", error);
    } finally {
      setState((prev) => ({ ...prev, isLoading: false, currentStep: 2 }));
    }
  };

  const completeStudy = async () => {
    let hasError = false;
    try {
      setState((prev) => ({ ...prev, isLoading: true }));
      const response = await callCompleteStudyAPI(user, "" + state.studyId);
      if (response) {
        navigate(-1);
        emitSnackbar("Study has been successfully added", "success");
      }
    } catch (error) {
      hasError = true;
      console.error("Error calling  API:", error);
    } finally {
      setState((prev) => ({ ...prev, isLoading: false }));
    }

    if (!hasError) {
      // TODO: We may need a way to identify if the user entered from "My Studies" vs "Other Studies" tab
      navigate("/market-research/manage-studies");
    }
  };

  const makeEditorsArray = () => {
    const editorsArray = state.editorsArray?.map((editor) => {
      return editor.user_guid;
    });
    if (editorsArray?.length === 0) {
      return null;
    }
    return editorsArray;
  };

  const addStudy = async () => {
    setState((prev) => ({ ...prev, isLoading: true }));

    const data = {
      vendor_name: state.vendorName,
      study_name: state.studyName,
      therapeutic_area: state.therapeuticArea,
      product: state.brand,
      start_date: state.startDate,
      end_date: state.endDate === "" ? null : state.endDate,
      editors: makeEditorsArray(),
      study_type: state.studyType,
    };
    try {
      const response = await callAddStudyAPI(user, data);

      if (response.study_id) {
        setState((prev) => ({ ...prev, currentStep: 1, studyId: response.study_id }));
      }
    } catch (err: AxiosError | unknown) {
      if (isAxiosError(err) && err.response?.data?.detail?.message) {
        if (err.response.data.detail.message.includes("already exists")) {
          emitSnackbar("Study already exists", "error");
        } else {
          emitSnackbar("Error adding study", "error");
        }
      } else {
        emitSnackbar("Error adding study", "error");
      }
    } finally {
      setState((prev) => ({ ...prev, isLoading: false }));
    }
  };

  const callGetAddStudyList = useQuery({
    queryKey: ["studyList"],
    queryFn: async () => {
      const types = ["vendor", "therapeutic_area", "product_name"];
      const callAPIs = types.map(async (type) => {
        const response = await callGetAddStudyListAPI(user, type);
        if (type === "vendor") {
          setVendorArray(response.items);
        }

        if (type === "therapeutic_area") {
          setTherapeuticAreaArray(response.items);
        }
        if (type === "product_name") {
          setProductArray(response.items);
        }
      });
      return Promise.all(callAPIs);
    },
    refetchOnMount: true,
    retry: false,
  });

  const {
    data: respondentIdsArray,
    isLoading: isRespondentIdsLoading,
    error: isRespondentIdsError,
  } = useQuery({
    queryKey: ["getRespondentIds", state.currentStep],
    queryFn: () => callGetRespondentIds(user, "" + state.studyId),
    enabled: state.currentStep === 2 && state.screenerSummaryFile !== null,
  });

  const {
    data: editorsList,
    isLoading: isEditorsLoading,
    error: isEditorsError,
  } = useQuery({
    queryKey: ["getEditorsList", state.openEditorModal],
    queryFn: () => callGetEditorsList(user),
    enabled: state.openEditorModal,
  });

  const getInitials = (name: string) => {
    const nameParts = name.split(" ");
    return nameParts
      .map((part) => part[0])
      .join("")
      .toUpperCase();
  };

  return {
    navigate,
    currentStep: state.currentStep,
    setCurrentStep: (step: number) => setState((prev) => ({ ...prev, currentStep: step })),
    stepsArray: state.steps,
    resetState,
    sanitizeString,
    checkForDuplicate,
    callGetAddStudyList,
    respondentIdsArray,
    isRespondentIdsLoading,
    isRespondentIdsError,
    uploadAudioFilesToS3,
    toggleEditorModal,
    isEditorModalOpen: state.openEditorModal,
    getInitials,
    getFileExtension,
    completeStudy,
    editorsList,
    isEditorsLoading,
    isEditorsError,

    // Arrays
    uploadDocsArray: array,
    vendorArray: state.vendorNameArray,
    theraputicArerArray: state.therapeuticAreaArray,
    brandArray: state.productArray,

    // Fields
    studyType: state.studyType,
    setStudyType,
    vendorName: state.vendorName,
    setVendorName,
    studyName: state.studyName,
    setStudyName,
    therapeuticArea: state.therapeuticArea,
    setTherapeuticArea,
    brand: state.brand,
    setBrand,
    startDate: state.startDate,
    setStartDate,
    endDate: state.endDate,
    setEndDate,
    screenerSummaryFile: state.screenerSummaryFile,
    setScreenerSummaryFile,
    discussionGuideFile: state.discussionGuideFile,
    setDiscussionGuideFile,
    screenerGuideFile: state.screenerGuideFile,
    setScreenerGuideFile,
    presentationFile: state.presentationFile,
    setPresentationFile,
    addAudioFile,
    setEditorsArray,
    editorArray: state.editorsArray,

    // Derived
    isNextDisabled,
    addStudy,
    uploadGuidesToS3,
    isLoading: state.isLoading,
  };
};
