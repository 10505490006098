import { MainPanel } from "../components/MarketResearch/Layout/Panels";
import { getAppEnv } from "../utils/application";

export default function TalkToDataPage() {
  let app_env = getAppEnv();
  app_env = app_env == "local" ? "dev" : app_env;

  return (
    <MainPanel sx={{ flex: 1, width: "100%" }}>
      <iframe
        style={{ height: "100%", width: "100%", border: 0 }}
        src={`https://cmg-oasis-${app_env}-talk2data.roche.com`}
      ></iframe>
    </MainPanel>
  );
}
