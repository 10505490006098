import { AutoAwesomeOutlined, ChatOutlined } from "@mui/icons-material";
import { Badge, Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { useDeepChatSocketContext } from "../../../contexts/DeepChatSocketContext";
import { BodyPanel, MainPanel, RightPanel } from "../Layout/Panels";
import useMarketResearchStore from "../useMarketResearchStore";
import InterviewComponent from "./InterviewComponent";
import QuestionInputBox from "./QuestionInputBox";

export default function ViewStudy() {
  const params = useParams();
  const navigate = useNavigate();
  const DeepChatSocket = useDeepChatSocketContext();

  const {
    studyQuestions,
    isLoadingQuestions,
    studyIds,
    setQuestion,
    setStudyId,
    question,
    getStudy,
    askQuestion,
    toggleLoadingChat,
    clearChatSession,
    toggleFilterDrawer,
    setScope,
    toggleStudySummary,
    respondentIdsArray,
    generateRespondentSummary,
    resetRespondentsArray,
    resetFilters,
    filterCount,
  } = useMarketResearchStore();

  // On Render check if studyId is synced
  useEffect(() => {
    if (params.studyId && params.studyId !== studyIds.at(0)) {
      setStudyId(params.studyId);
    }
  }, [params]);

  //set respondentIdsArray to default array on render
  useEffect(() => {
    resetFilters();
    resetRespondentsArray();
    setScope("chat");
  }, []);

  // Try to get study details by its "study_key"
  const studyId = studyIds.at(0);
  const study = studyId ? getStudy(studyId) : undefined;

  const askDeepchatQuestion = (question: string, question_id?: string, numOfResponses?: number) => {
    if (study?.study_title) {
      clearChatSession();
      toggleLoadingChat(true);
      toggleFilterDrawer(false);
      askQuestion(DeepChatSocket.sendJsonMessage, question, [study.study_key], question_id, numOfResponses);
      navigate(`/market-research/threads/`);
    }
  };

  const handleChangeStudy = () => {
    clearChatSession();
    navigate("/market-research");
  };

  // Example Question: What feedback is being given about Actemra?
  // Example Question: How many moderate to severe RA patients do specialists manage in a typical month? How many of those patients are on advanced biologics such as Humira or Enbrel?
  return (
    <MainPanel>
      <BodyPanel>
        <Box sx={{ padding: "24px", display: "flex", flexDirection: "column" }}>
          <Box sx={{ flex: 0 }}>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
                flexDirection={"row"}
              >
                <Typography variant="h5" sx={{ color: "#004677" }}>
                  {study?.study_title}
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                  <Link to={`/market-research/study/${studyId}/summary`} style={{ textDecoration: "none" }}>
                    <Button variant="outlined" color="primary" sx={{ color: "#003087" }} onClick={toggleStudySummary}>
                      STUDY SUMMARY
                    </Button>
                  </Link>
                  <Link
                    to={"/market-research"}
                    onClick={(_) => {
                      _.preventDefault();
                      handleChangeStudy();
                    }}
                    style={{ textDecoration: "none", color: "#003087" }}
                  >
                    <Button color="primary" sx={{ color: "#003087" }}>
                      CHANGE STUDY
                    </Button>
                  </Link>
                </Box>
              </Box>
            </Box>
            <Box sx={{ my: "2em" }}>
              <QuestionInputBox
                loading={false}
                onQuestionInput={(text: string) => {
                  setQuestion(text);
                }}
                filterCount={filterCount()}
                onFilterClick={() => toggleFilterDrawer()}
                onSubmit={() => askDeepchatQuestion(question)}
                question={question}
                placeholder="Ask Deepsense..."
              />
            </Box>
          </Box>

          {isLoadingQuestions ? (
            <Box
              sx={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box
              sx={{
                paddingRight: "10px",
                flex: 1, // Allow the box to grow and shrink within available space
                overflowY: "auto",
                maxHeight: "calc(100vh - 355px)", // I give up, this is the best I got for a scrolling tile set but fixed chat input
              }}
            >
              <Box sx={{ overflowY: "auto", minHeight: 0 }}>
                <Typography variant="h6" sx={{ marginBottom: "1rem" }}>
                  Not sure what to ask?
                </Typography>
                <Box>
                  {(studyQuestions ?? []).length > 0 ? (
                    <Grid container spacing={2} direction={"row"}>
                      {(studyQuestions ?? []).map((question, i) => {
                        return (
                          <Grid item xs={12} sm={6} md={4} key={i}>
                            <Box
                              flexDirection={"row"}
                              gap={3}
                              onClick={() => {
                                askDeepchatQuestion(question.question_title, question.question_id, question.responses);
                              }}
                              sx={{
                                background: "#EFF0F0",
                                padding: "20px",
                                borderRadius: "10px",
                                display: "flex",
                                cursor: "pointer",
                                height: "100%",
                              }}
                            >
                              <Badge
                                badgeContent={question.responses}
                                color="secondary"
                                sx={{
                                  "& .MuiBadge-badge": {
                                    color: "white",
                                  },
                                }}
                              >
                                <ChatOutlined fontSize="large" sx={{ color: "#003087", marginTop: "5px" }} />
                              </Badge>
                              <Typography variant="h6" sx={{ fontSize: "14px" }}>
                                {question.question_title}
                              </Typography>
                            </Box>
                          </Grid>
                        );
                      })}
                    </Grid>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="h6" sx={{ fontSize: "20px", color: "#13294B" }}>
                        No Questions Available
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </BodyPanel>

      <RightPanel sx={{ display: "flex", flexDirection: "column" }}>
        <>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem", height: "93%" }}>
            <InterviewComponent {...study} fromStudySummary={false} respondantIds={respondentIdsArray} />
          </Box>

          <Button
            variant="outlined"
            onClick={() => {
              clearChatSession();
              toggleLoadingChat(true);
              generateRespondentSummary(DeepChatSocket.sendJsonMessage);
              navigate(`/market-research/study/${studyId}/generate-summary`);
            }}
            sx={{
              px: "1rem",
              color: "#003087",
              justifyContent: "center",
            }}
            startIcon={<AutoAwesomeOutlined />}
          >
            Generate Summary
          </Button>
        </>
      </RightPanel>
    </MainPanel>
  );
}
