import { Box, Card, Divider, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import cardiovascularIcon from "../../../icons/pmr/study/cardiovascular.svg";
import inflamatoryIcon from "../../../icons/pmr/study/inflammatory_disease.svg";
import oncologyIcon from "../../../icons/pmr/study/oncology.svg";
import respiratoryIcon from "../../../icons/pmr/study/respiratory_disease.svg";
import { MarketResearchStudy } from "../models";

interface StudyTileProps {
  study: MarketResearchStudy;
}

export default function StudyTile({ study }: StudyTileProps) {
  const navigate = useNavigate();
  const [hovered, setHovered] = useState(false);

  const getStudyIcon = (studyId: string) => {
    switch (studyId) {
      case "1574432872":
        return inflamatoryIcon;

      case "149225203":
        return oncologyIcon;

      case "735670018":
        return respiratoryIcon;

      default:
        return cardiovascularIcon; // Default icon if no match is found
    }
  };

  const studyPath = `/market-research/study/${study.study_key}`;
  return (
    <Box sx={{ minWidth: "260px" }}>
      <Link
        to={studyPath}
        style={{ textDecoration: "none" }}
        onClick={(e) => {
          e.preventDefault();
          navigate(studyPath);
        }}
      >
        <Card
          onMouseOver={() => setHovered(true)}
          onMouseOut={() => setHovered(false)}
          sx={{
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem", padding: "20px" }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
              <Typography
                variant="h5"
                sx={{ fontSize: "24px", height: "4rem", overflow: "hidden" }}
                title={study.study_title}
              >
                {study.study_title}
              </Typography>
              <Divider />
              <Box sx={{ display: "flex", gap: "0.8rem" }} alignItems={"center"}>
                <img src={getStudyIcon(study.study_key)} width={"50px"} height={"50px"} />
                <Box sx={{ display: "flex", gap: "0.5rem", flexDirection: "column" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "0.2rem",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography variant="h6" sx={{ fontSize: "10px", color: " rgba(66, 84, 102, 0.6)" }}>
                      FIELDED
                    </Typography>
                    <Typography variant="h6" sx={{ fontSize: "13px", color: "#004677", textWrap: "nowrap" }}>
                      {dayjs(study.study_date).format("MMMM D, YYYY")}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      padding: "0.3rem",
                      backgroundColor: "#00A3E0",
                      borderRadius: "10px",
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h6" sx={{ fontSize: "13px", color: "white" }}>
                      {study.num_respondents} respondents
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          {/* {hovered && (
            <Box sx={{ width: "100%", height: "100px", backgroundColor: "#EFF0F0", display: "none" }}>
              {Array.from(Array(10).keys()).map((i) => {
                return (
                  <ul key={i}>
                    <li>
                      <Typography variant="h6" sx={{ fontSize: "14px" }}>
                        1
                      </Typography>
                    </li>
                  </ul>
                );
              })}
            </Box>
          )} */}
        </Card>
      </Link>
    </Box>
  );
}
