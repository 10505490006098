import { useQuery } from "@tanstack/react-query";
import { atom, useAtom } from "jotai";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  callCompleteStudyAPI,
  callGetEditorStudies,
  ManageStudyListResponse,
  StudyListParams,
} from "../../../api/dataSources";
import emitSnackbar from "../../../emitSnackbar";
import useUserContext from "../../Authorization/useUserContext";
import { ManageStudiesState } from "../models";

const ManageStudiesStateAtom = atom<ManageStudiesState>({
  studyCounts: {},
  isAPILoading: false,
  studyId: undefined,
  currentIndex: 0,
});
const StudyListParamsAtom = atom<StudyListParams>({ permission_level: "OWNER", page: 1, page_size: 10 });

export const useManageStudiesStore = () => {
  const user = useUserContext();
  const navigate = useNavigate();
  const [ManageStudies, setManageStudies] = useAtom(ManageStudiesStateAtom);
  // Any state variables used in a hook that is called in multiple places HAS to use an atom
  const [StudyListParams, setStudyListParams] = useAtom(StudyListParamsAtom);

  const setStudyId = (studyId: string) => {
    setManageStudies((_) => ({ ..._, studyId }));
  };

  const setListView = (view: "OWNER" | "EDITOR") => {
    setStudyListParams((_) => ({ ..._, page: 1, permission_level: view }));
  };

  const setPage = (page: number) => {
    setStudyListParams((_) => ({ ..._, page }));
  };

  const getViewRecordCount = (view: "OWNER" | "EDITOR") => {
    return ManageStudies.studyCounts[view];
  };

  // Get editable studies
  const { data, isLoading, refetch } = useQuery<ManageStudyListResponse>({
    queryKey: ["pmr:managed_studies", StudyListParams],
    queryFn: () => callGetEditorStudies(user, StudyListParams),
    refetchOnWindowFocus: "always",
  });

  useEffect(() => {
    if (data?.total_count) {
      setManageStudies((_) => {
        const next = { ..._, studyCounts: { ..._.studyCounts } };
        // Sync the study count to the target "permission_level" so we can keep a count per view
        next.studyCounts[StudyListParams.permission_level] = data.total_count;
        return next;
      });
    }
  }, [data, StudyListParams]);

  const setCurrentIndex = (index: number) => {
    setManageStudies((_) => ({ ..._, currentIndex: index }));
  };

  const completeStudy = async () => {
    try {
      setManageStudies((prev) => ({ ...prev, isAPILoading: true }));
      const response = await callCompleteStudyAPI(user, `${ManageStudies.studyId}`);
      if (response) {
        navigate(-1);
        emitSnackbar("Study completed", "success", { autoHideDuration: 3000 });
      }
    } catch (error) {
      // hasError = true;
      console.error("Error calling  API:", error);
    } finally {
      setManageStudies((prev) => ({ ...prev, isLoading: false }));
    }

    // if (!hasError) {
    //   // TODO: We may need a way to identify if the user entered from "My Studies" vs "Other Studies" tab
    //   // navigate("/market-research/manage-studies");
    //   navigate(-1);
    // }
  };

  const resetState = () => {
    setManageStudies((_) => ({ ..._, studyId: undefined, isAPILoading: false }));
  };

  return {
    data,
    isLoading,
    StudyListParams,
    setPage,
    setListView,
    ManageStudies,
    getViewRecordCount,
    completeStudy,
    setStudyId,
    resetState,
    refetch,
    setCurrentIndex,
  };
};
