import { ArticleOutlined, AutoAwesomeOutlined, Grading } from "@mui/icons-material";
import { useCustomTabs } from "../api/customTabs";
import Roles from "../components/Authorization/Roles";
import useUserContext from "../components/Authorization/useUserContext";
import { Feature } from "../featureFlags/featureFlagConfig";
import useFeatureFlag from "../featureFlags/useFeatureFlag";
import { MenuItemType } from "../utils/models";

export const useTopBarStore = () => {
  const user = useUserContext();

  // Role Logic
  const selectedRoles = user.selectedRoles;
  const isMedOrCom = selectedRoles?.some((_role) => [Roles.MEDICAL, Roles.COMMERCIAL].includes(_role));
  const isCommercial = selectedRoles?.includes(Roles.COMMERCIAL);
  const isUSLT = selectedRoles?.includes(Roles.USLT_RETRO);
  const isPMR = selectedRoles?.includes(Roles.PRIMARY_MARKET_RESEARCH);
  const isSales = selectedRoles?.includes(Roles.SALES);

  // Feature Flag Logic
  const StructuredData = useFeatureFlag(Feature.StructuredData);
  const Retros = useFeatureFlag(Feature.Retros);
  const PrimaryMarketResearch = useFeatureFlag(Feature.PrimaryMarketResearch);

  const { customTabs, isLoading } = useCustomTabs();

  const menus: MenuItemType[] = [
    {
      label: "Chat",
      link: "/deepchat",
      startIcon: AutoAwesomeOutlined,
      enabled: isMedOrCom,
    },
    {
      label: "Explore",
      link: "/explorer",
      startIcon: ArticleOutlined,
      enabled: isMedOrCom,
    },
    {
      label: "Primary Market Research",
      link: "/market-research",
      enabled: isPMR && !isMedOrCom, // isPMR && PrimaryMarketResearch.enabled, // Manually disabled until we review PMR security as a part of future work effort
    },
    {
      label: "Talk To Data",
      link: "/talktodata",
      enabled: isSales,
    },
    // { // Disabled per LEIBNIZ-2452
    //   label: "Sales Data",
    //   link: "/salesdata",
    //   startIcon: Grading,
    //   enabled: isCommercial && StructuredData.enabled,
    // },
    {
      label: "Retros",
      link: "/dashboards/retros",
      startIcon: Grading,
      enabled: isUSLT && Retros.enabled,
    },
  ];

  return {
    customTabs,
    isLoading,
    menus: menus.filter((item) => item.enabled),
    isMedOrCom,
  };
};
