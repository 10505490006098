import { getAppEnv } from "../utils/application";
import { featureFlagConfig } from "./featureFlagConfig";

export interface FeatureFlagState {
  enabled: boolean;
}

export default function useFeatureFlag(flagName: string): FeatureFlagState {
  const app_env = getAppEnv();

  const feature = featureFlagConfig.find((_config) => _config.feature === flagName);

  if (feature === undefined) {
    console.warn(`Feature flag "${flagName}" not found in config`);
  }

  const featureEnvironments = feature?.env ?? [];

  return { enabled: featureEnvironments.some((_env) => [app_env, "all"].includes(_env)) };
}
