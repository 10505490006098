import { AddSharp } from "@mui/icons-material";
import { Avatar, AvatarGroup, Box, Button, Divider, FormControl, TextField } from "@mui/material";
import { StyledModal } from "../../common";
import AddStudyEditors from "../AddStudy/AddEditorsModal";
import Dropdown from "../AddStudy/DropDown";
import UploadDocsComponent from "../AddStudy/UploadDocsComponent";
import { useAddStudyStore } from "../AddStudy/useAddStudyStore";

export default function EditStudyOverview() {
  const {
    setScreenerSummaryFile,
    setDiscussionGuideFile,
    setScreenerGuideFile,
    setPresentationFile,
    getInitials,
    editorArray,
    toggleEditorModal,
    isEditorModalOpen,
    uploadDocsArray,
  } = useAddStudyStore();

  const getFile = (from: number, file: File | null) => {
    switch (from) {
      case 0:
        setScreenerSummaryFile(file);
        break;
      case 1:
        setDiscussionGuideFile(file);
        break;
      case 2:
        setScreenerGuideFile(file);
        break;
      case 3:
        setPresentationFile(file);
        break;
      default:
        break;
    }
  };

  return (
    <Box>
      <FormControl
        size="medium"
        variant="outlined"
        fullWidth
        sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "1rem" }}
      >
        <Box sx={{ display: "flex", gap: "1rem" }}>
          <Dropdown
            label="*Study Type"
            array={["Quantitative", "Qualitative"]}
            value={"Anuj"}
            onChange={(value) => {
              //   setStudyType(value);
            }}
          />
          <Dropdown
            label="*Vendor Name"
            array={[]}
            value={"Anuj"}
            onChange={(value) => {
              //   setVendorName(value);
            }}
          />
        </Box>
        <Box sx={{ display: "flex", gap: "1rem" }}>
          <TextField
            fullWidth
            label="*Study Name"
            variant="outlined"
            required
            // value={studyName}
            // onChange={(e) => setStudyName(e.target.value)}
          />
          <Dropdown
            label="*Therapeutic Area"
            array={[]}
            value={"therapeuticArea"}
            onChange={(value) => {
              //   setTherapeuticArea(value);
            }}
          />
        </Box>
        <Box sx={{ display: "flex", gap: "1rem" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", flex: 1, gap: "1rem" }}>
            <TextField
              label="Start Date"
              variant="outlined"
              required
              fullWidth
              InputLabelProps={{ shrink: true }}
              type="date"
              inputProps={{ pattern: "\\d{2}-\\d{2}-\\d{4}", placeholder: "MM-DD-YYYY" }}
              //   onChange={(e) => setStartDate(e.target.value)}
              //   value={startDate}
            />
            -
            <TextField
              label="End Date"
              variant="outlined"
              fullWidth
              InputLabelProps={{ shrink: true }}
              //   value={endDate}
              type="date"
              inputProps={{ pattern: "\\d{2}-\\d{2}-\\d{4}", placeholder: "MM-DD-YYYY" }}
              //   onChange={(e) => setEndDate(formatToMMDDYYYY(e.target.value))}
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mt: "1rem", flex: 1 }}>
            <AvatarGroup max={5}>
              {editorArray?.map((editor, index) => (
                <Avatar alt={editor.auth_user_id} sx={{ bgcolor: "#003087" }} key={index}>
                  {getInitials(editor.auth_user_id)}
                </Avatar>
              ))}
            </AvatarGroup>
            <Button
              onClick={() => {
                toggleEditorModal(true);
              }}
              variant="text"
              color="primary"
              size="medium"
              startIcon={<AddSharp sx={{ color: "#003087" }} />}
              sx={{ color: "#003087", fontSize: "15px" }}
            >
              ADD EDITORS
            </Button>
          </Box>
        </Box>
      </FormControl>
      <Divider sx={{ mt: "2rem", mb: "1.5rem" }} />
      <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "0.5rem" }}>
        {uploadDocsArray.map((item) => (
          <UploadDocsComponent
            id={item.id}
            key={item.id}
            header={item.header}
            Icon={item.Icon}
            acceptedFiles={item.accpetedFiles}
            fromEdit={true}
            fromAudio={false}
            registerAudioFile={(file) => {
              getFile(item.id, file);
            }}
          />
        ))}
      </Box>

      <StyledModal
        children={<AddStudyEditors />}
        open={isEditorModalOpen}
        handleModalClose={() => {
          toggleEditorModal(false);
        }}
        title="Add Study Editors"
      />
    </Box>
  );
}
