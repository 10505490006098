import { Delete, FileDownloadDoneOutlined, RepeatSharp, SpatialAudioOffOutlined } from "@mui/icons-material";
import { Box, Button, Divider, IconButton, Input, SvgIconTypeMap, Typography } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { useRef, useState } from "react";
import emitSnackbar from "../../../emitSnackbar";
import { TUploadDocs } from "../models";
import { useAddStudyStore } from "./useAddStudyStore";
export default function UploadDocsComponent({
  id,
  header,
  Icon,
  respondentId,
  audioId,
  fromAudio = false,
  registerAudioFile,
  acceptedFiles,
  fromEdit = false,
}: TUploadDocs) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const [isDragging, setIsDragging] = useState(false);

  const {
    vendorName,
    studyName,
    startDate,
    sanitizeString,
    checkForDuplicate,
    getFileExtension,
    presentationFile,
    screenerSummaryFile,
    screenerGuideFile,
    discussionGuideFile,
  } = useAddStudyStore();

  const StyledIcon = ({ Icon }: { Icon: OverridableComponent<SvgIconTypeMap & any> }) => {
    return <Icon sx={{ width: "30px", height: "30px", color: "white" }} fontSize="medium" />;
  };

  const handleDelete = () => {
    setFile(null);
    registerAudioFile(null);
  };
  const handleUpload = () => {
    inputRef.current?.click();
  };

  const handleFileUpload = (file?: File) => {
    if (file && !checkForDuplicate(file)) {
      setFile(file);
      registerAudioFile(file);
    } else {
      emitSnackbar(
        `This file ${file?.name} has been uploaded more than once. Please review uploaded files and make the necessary changes.`,
        "info",
        { autoHideDuration: 30000 },
      );
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);

    const droppedFiles = e.dataTransfer.files;
    if (!droppedFiles.length) return;

    const file = droppedFiles[0];
    const extension = getFileExtension(file.name)?.toLowerCase().replace(".", "");

    const allowedExtensions = {
      audio: ["mp3", "wav", "m4a", "flac", "aac", "wma", "ogg"],
      0: ["csv", "xml"],
      1: ["pdf", "doc", "docx"],
      2: ["pdf", "doc", "docx"],
      3: ["ppt", "pptx"],
    };

    const extensionsToCheck = fromAudio ? allowedExtensions.audio : allowedExtensions[id];

    if (!extensionsToCheck) {
      handleFileUpload(file);
      return;
    }

    // Check if file extension is allowed
    if (!extensionsToCheck.includes(extension)) {
      const allowedExtensionsText = extensionsToCheck.map((ext: string) => `.${ext}`).join(", ");
      emitSnackbar(`Only ${allowedExtensionsText} files are allowed`, "error", {
        autoHideDuration: 10000,
      });
      return;
    }

    // File is valid, proceed with upload
    handleFileUpload(file);
  };

  const disableView = () => {
    const isPresentationSection: boolean = header === "Presentation";
    const hasPresentationFile: boolean = Boolean(presentationFile);
    const hasOtherFiles: boolean = Boolean(screenerSummaryFile || screenerGuideFile || discussionGuideFile);

    const isDisabled: boolean =
      (hasPresentationFile && !isPresentationSection) || (hasOtherFiles && isPresentationSection);

    return {
      opacity: isDisabled ? 0.5 : 1,
      cursor: isDisabled ? "not-allowed" : "pointer",
      pointerEvents: isDisabled ? "none" : "auto",
    };
  };

  const handleTemplateDownload = () => {
    const link = document.createElement("a");
    link.href = `/assets/screener_summary_template.csv`;
    link.download = "Screener_Summary_Template.csv"; // Name of the downloaded file
    link.click();
  };

  return (
    <Box key={header} sx={disableView()}>
      {header === "Presentation" && <Divider sx={{ mt: "1rem", mb: "1.2rem" }} />}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h6" sx={{ fontSize: "12px", fontWeight: "400" }}>
          {header}
        </Typography>
        {header === "Screener Summary" && (
          <Box
            sx={{ display: "flex", alignItems: "center", ml: "2px", cursor: "pointer" }}
            onClick={handleTemplateDownload}
          >
            -
            <Typography sx={{ fontSize: "12px", fontWeight: "400", textDecoration: "underline", ml: "2px" }}>
              download template
            </Typography>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          width: "100%",
          borderWidth: file ? "0px" : "1px",
          borderStyle: "dashed",
          mt: "0.5rem",
          borderRadius: "5px",
          display: "flex",
          padding: "0.5rem",
          border: file ? "0px" : isDragging ? "1px dashed #003087" : "1px dashed #BDBDBD",
          backgroundColor: isDragging ? "rgba(0, 48, 135, 0.1)" : "transparent",
          transition: "all 0.3s ease-in-out",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "5px", flexBasis: file || !fromAudio ? "0%" : "40%" }}>
          <Box
            sx={{
              width: "3rem",
              height: "3rem",
              background: file ? " #00A3E0" : " rgba(0, 163, 224, 0.3)",
              display: "flex",
              borderRadius: "5px",
              justifyContent: "center",
              alignItems: "center",
              flexShrink: "0",
            }}
          >
            <StyledIcon Icon={Icon || SpatialAudioOffOutlined} />
          </Box>
          {respondentId && !file && (
            <Typography variant="h6" sx={{ fontSize: "16px", fontWeight: "400", color: "#13294B" }}>
              Audio ID: {audioId}
            </Typography>
          )}
        </Box>
        {file ? (
          <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
            <Box sx={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}>
              <Typography variant="h6" sx={{ fontSize: "16px", fontWeight: "400", color: "13294B" }}>
                {sanitizeString(vendorName)}_{sanitizeString(studyName)}_
                {fromAudio
                  ? sanitizeString("audio_transcript") + "_" + sanitizeString(audioId ?? "")
                  : sanitizeString(header ?? "")}
                _{sanitizeString(startDate)}
              </Typography>
              <Typography variant="h6" sx={{ fontSize: "12px", fontWeight: "400", color: "13294B" }}>
                Original file name: {file.name}
              </Typography>
            </Box>
            {fromEdit ? (
              <Box sx={{ display: "flex" }}>
                <IconButton color="primary" size="medium" onClick={handleDelete}>
                  <RepeatSharp />
                </IconButton>
                <IconButton color="primary" size="medium" onClick={handleDelete}>
                  <FileDownloadDoneOutlined />
                </IconButton>
              </Box>
            ) : (
              <IconButton color="primary" size="medium" onClick={handleDelete}>
                <Delete />
              </IconButton>
            )}
          </Box>
        ) : (
          <Box
            id={header}
            key={header}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              gap: "10px",
            }}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onDragLeave={handleDragLeave}
          >
            <Typography variant="h6" sx={{ fontSize: "16px", fontWeight: "400" }}>
              Drag and drop files or
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              onClick={handleUpload}
              sx={{ border: "1px solid rgba(0, 48, 135, 0.5)", color: " #003087" }}
            >
              Browse...
              <Input
                type="file"
                inputProps={{ accept: acceptedFiles }}
                inputRef={inputRef}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileUpload(event.target.files?.[0])}
                style={{ display: "none" }} // Hide the input element
              />
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
}
