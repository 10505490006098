import { MoreHoriz, RocketLaunch, CheckCircleOutline, Check, Error } from "@mui/icons-material";
import { Chip, Avatar, Badge } from "@mui/material";
import React from "react";

export default function StatusIndicator({
  status = "",
  showBadge = false,
  avatarBgColor = "rgba(0, 0, 0, 0.2)",
  iconColor = "white",
}) {
  const { icon, color, label } = getStatusLabelIconColor(status);

  const ChipContent = (
    <Chip
      avatar={
        <Avatar sx={{ bgcolor: avatarBgColor }}>
          {React.cloneElement(icon, { sx: { color: iconColor, width: "16px" } })}
        </Avatar>
      }
      label={label}
      size="small"
      sx={{
        bgcolor: color,
        color: "white",
        // height: "36px",
        borderRadius: "20px",
        width: "120px",
        justifyContent: "start",
        "& .MuiChip-avatar": { marginRight: "2px" },
      }}
    />
  );

  return showBadge ? (
    <Badge
      overlap="circular"
      badgeContent=""
      variant="dot"
      color={"error"}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      sx={{ "& .MuiBadge-dot": { minWidth: "8px", height: "8px", right: "10px", top: "4px" } }}
    >
      {ChipContent}
    </Badge>
  ) : (
    ChipContent
  );
}

const getStatusLabelIconColor = (status: string) => {
  // Options from API interface:
  // - created
  // - gathering_inputs
  // - all_audio_transcribed
  // - processing
  // - published

  switch (status) {
    case "created":
    case "gathering_inputs":
      return {
        icon: <MoreHoriz />,
        color: "#EF6C00",
        label: "Waiting for More",
      };

    // TODO: Not sure what were doing with this state
    case "all_audio_transcribed":
      return {
        icon: <CheckCircleOutline />,
        color: "#EF6C00",
        label: "Has All Files",
      };

    case "transcribing":
    case "processing":
      return {
        icon: <RocketLaunch />,
        color: "#00A3E0",
        label: "Processing",
      };

    case "published":
      return {
        icon: <Check />,
        color: "#2E7D32",
        label: "Available to Chat",
      };

    default:
      return { icon: <Error />, color: "#D32F2F", label: "File Error" };
  }
};
