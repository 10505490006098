import { useQuery } from "@tanstack/react-query";
import { useAtom } from "jotai";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { SendJsonMessage } from "react-use-websocket/dist/lib/types";
import {
  callGetAllStudies,
  callGetMRQuestions,
  callGetQuestionSummary,
  callMRFilters,
  callMRViewTranscripts,
  callPMRStudySummary,
} from "../../api/dataSources";
import { FilterResponse } from "../../models/filter";
import useUserContext from "../Authorization/useUserContext";
import {
  MarketResearchDrawerStateAtom,
  MarketResearchStateAtom,
  MarketResearchStudy,
  StudyQuestion,
  TMRTranscripts,
  TPMRChat,
  TStudySummary,
} from "./models";
import { MarketResearchSocketThreadAtom } from "../../store/market-research/useMarketResearchSocket";

const useMarketResearchStore = () => {
  const user = useUserContext();
  const [state, setState] = useAtom(MarketResearchStateAtom);
  const [drawer, setDrawer] = useAtom(MarketResearchDrawerStateAtom);
  const [, setSocketThread] = useAtom(MarketResearchSocketThreadAtom);
  const navigate = useNavigate();

  const search = {
    base_filters: {
      ...state.base_filters,
    },
  };

  // Data: All Studies
  const { data: studies, isLoading: isLoadingStudies } = useQuery<MarketResearchStudy[]>({
    queryKey: ["pmr:all_studies", JSON.stringify(search)],
    queryFn: () => callGetAllStudies(user, search),
  });

  // Data: Study Questions by StudyID
  const { data: studyQuestions, isLoading: isLoadingQuestions } = useQuery<StudyQuestion[]>({
    queryKey: ["pmr:study_questions", state.studyIds, JSON.stringify(search)],
    queryFn: () => callGetMRQuestions(user, state.studyIds.at(0) ?? "", search),
    enabled: state.studyIds.length == 1,
  });

  // TODO: This is the entry point for populating the facet bar but we may need another for the "list" view vs "chat" view
  const { data: facets, isLoading: isLoadingFacets } = useQuery<FilterResponse>({
    queryKey: ["pmr:facets", state.scope, JSON.stringify(state.studyIds)],
    queryFn: () =>
      callMRFilters(user, {
        scope: state.scope,
        base_filters: {
          study: state.studyIds,
        },
      }),
  });

  const { data: transcripts, isLoading: isLoadingTranscript } = useQuery<TMRTranscripts>({
    queryKey: ["pmr:transcripts", state.studyIds, state.respondentId],
    queryFn: () => callMRViewTranscripts(user, state.studyIds.at(0) ?? "", state.respondentId ?? ""),
    enabled: state.studyIds.length > 0 && !!state.respondentId,
    retry: (failureCount, error) => {
      // Retry only if failure count is less than 2 and error is not a 404
      if (failureCount >= 2) return false;
      return true;
    },
  });

  const askQuestion = (
    sendJsonMessage: SendJsonMessage,
    question: string,
    studyIds: string[],
    question_id?: string,
    numOfResponses?: number | undefined,
  ) => {
    // Somewhat hacky way to establish what kind of interaction the chat is:
    // If there is a question_id, assume that a "QnA Tile" was clicked on

    setState((_) => ({ ..._, invocationType: question_id ? "qna" : "user", noOfResponses: numOfResponses }));

    sendJsonMessage({
      action: "streamChat",
      service_requested: "deepchat",
      user_request: {
        query: question,
        search_request: {
          base_filters: { study: studyIds, ...state.base_filters },
        },
        question_id,
      },
    });
  };

  const { data: studySummaryResponse, isLoading: isStudySummaryLoading } = useQuery<TStudySummary>({
    queryKey: ["pmr:study_summary", state.studyIds],
    queryFn: () => callPMRStudySummary(user, state.studyIds.at(0) ?? ""),
    enabled: state.studyIds.length > 0 && !!state.showStudySummary,
  });

  const { data: qaChatResponse, isLoading: isQAChatLoading } = useQuery<TPMRChat>({
    queryKey: ["pmr:study_summary", state.questionId],
    queryFn: () => callGetQuestionSummary(user, state.questionId ?? ""),
    enabled: !!state?.questionId,
  });

  const setFilter = (filterName: string, value: string, isChecked: boolean) => {
    setState((prev) => {
      const currentValues = prev.base_filters[filterName] || [];

      const updatedValues = isChecked
        ? [...currentValues, value] // Add the value if checked
        : currentValues.filter((v) => v !== value); // Remove the value if unchecked

      const _base_filters = { ...prev.base_filters };

      if (updatedValues.length > 0) {
        // If the array has values, update the key
        _base_filters[filterName] = updatedValues;
      } else {
        // If the array is empty, remove the key
        delete _base_filters[filterName];
      }

      return {
        ...prev,
        base_filters: _base_filters, // Ensure a new reference
      };
    });
  };

  // Process the data to extract the required arrays
  const { respondentIdsArray, facetsArray } = useMemo(() => {
    if (!facets || !facets.common_filters) {
      return { respondentIds: [], otherFilterValues: [] };
    }

    const respondentIdFilter = facets.common_filters.find((filter) => filter.column_name === "respondent_id");
    const respondentIdsArray = respondentIdFilter ? respondentIdFilter.filter_values : [];
    const facetsArray = facets.common_filters
      .filter((filter) => filter.column_name !== "respondent_id")
      .map((filter) => ({
        filter_name: filter.filter_name,
        column_name: filter.column_name,
        filter_values: filter.filter_values,
      }));
    return { respondentIdsArray, facetsArray };
  }, [facets]);

  const updateRespondentFilterJson = (uncheckedValue: string) => {
    setState((prevState) => {
      // Clone the existing filterJson
      const updatedFilterJson = { ...prevState.base_filters };
      // Ensure base_filters.respondent_id is initialized as a string array or use the default
      const currentRespondentIds: string[] = updatedFilterJson.respondent_id ?? respondentIdsArray ?? [];
      let updatedRespondentIds: string | any[] | undefined;
      if (currentRespondentIds.includes(uncheckedValue)) {
        // Remove the unchecked value from respondent_id
        updatedRespondentIds = currentRespondentIds.filter((id: string) => id !== uncheckedValue);
      } else {
        // Add the unchecked value to respondent_id
        updatedRespondentIds = [...currentRespondentIds, uncheckedValue];
      }

      // Update respondent_id in base_filters
      if (updatedRespondentIds.length > 0) {
        updatedFilterJson.respondent_id = updatedRespondentIds;
      } else {
        // Remove respondent_id key if no values remain
        updatedFilterJson.respondent_id = [];
      }

      return {
        ...prevState,
        base_filters: updatedFilterJson, // Update filterJson in the state
      };
    });
  };

  // Assumes that the ReactQuery for "pmr:all_studies" is loaded
  const getStudy = (study_key: string) => {
    return studies?.find((study) => study.study_key == study_key);
  };

  const editQuestion = (question: string) => {
    resetSocket();
    clearChatSession();
    setState((_) => ({ ..._, question }));
    navigate(-1);
  };

  const resetSocket = () => {
    setSocketThread((_) => _ + 1);
  };

  // Clear will retain the selected studyId
  const clearChatSession = () => {
    setState((_state) => ({
      ..._state,
      question: "",
      invocationType: undefined,
      isLoading: false,
      socketStatusMessage: "",
      messages: [],
    }));
  };

  // Reset will clear everything and reset back to default state
  const resetState = () => {
    setState({
      studyIds: [],
      isLoading: false,
      socketStatusMessage: "",
      messages: [],
      question: "",
      base_filters: {},
      respondentId: "",
      filterCount: 0,
      scope: "list",
    });

    // Reset Filter Accordion State
    toggleFilterDrawer(false);
    // toggleExpanded([]);
  };

  const toggleFilterDrawer = (open?: boolean) => {
    if (open === undefined) {
      setDrawer((_) => ({ ..._, open: !_.open }));
    } else {
      setDrawer((_) => ({ ..._, open }));
    }
  };

  const toggleExpanded = (sectionKey: string | string[]) => {
    setDrawer((_) => {
      if (Array.isArray(sectionKey)) {
        return { ..._, expanded: sectionKey };
      } else {
        const expanded = _.expanded.includes(sectionKey)
          ? _.expanded.filter((key) => key != sectionKey)
          : [..._.expanded, sectionKey];

        return { ..._, expanded };
      }
    });
  };

  const isExpanded = (sectionKey: string) => {
    return drawer.expanded.includes(sectionKey);
  };

  const isFacetChecked = (column_name: string, value: string) => {
    return (state.base_filters[column_name] ?? []).includes(value);
  };

  const filterCount = () =>
    Object.keys(search.base_filters)
      .filter((filter_key) => !["respondent_id"].includes(filter_key))
      .map((filter_key) => (search.base_filters[filter_key] ?? []).length)
      .reduce((cur, val) => cur + val, 0);

  const hasFilters = () => {
    return filterCount() > 0;
  };

  const resetFilters = () => {
    setState((_state) => ({
      ..._state,
      base_filters: {},
    }));
  };

  const setScope = (scope: "list" | "chat") => {
    setState((_) => ({ ..._, scope }));
  };

  const toggleLoadingChat = (isLoading?: boolean) => {
    if (isLoading === undefined) {
      setState((_) => ({ ..._, isLoading: !_.isLoading }));
    } else {
      setState((_) => ({ ..._, isLoading }));
    }
  };

  const generateRespondentSummary = (sendJsonMessage: SendJsonMessage) => {
    sendJsonMessage({
      action: "streamChat",
      service_requested: "pmr_study_summary",
      user_request: {
        search_request: {
          base_filters: {
            study: state.studyIds,
            respondent_ids: search.base_filters["respondent_id"] ?? respondentIdsArray,
          },
        },
      },
    });
  };

  const resetRespondentsArray = () => {
    setState((prevState) => ({
      ...prevState,
      base_filters: { ...prevState.base_filters, respondent_id: undefined },
    }));
  };

  return {
    // -- Store Methods
    askQuestion,
    toggleLoadingChat,
    isLoadingChat: state.isLoading,
    socketStatusMessage: state.socketStatusMessage,
    invocationType: state.invocationType,
    getStudy,
    editQuestion,
    clearChatSession,
    filterCount,
    resetFilterCount: () => setState((_) => ({ ..._, filterCount: 0 })),
    resetState,
    resetSocket,

    // -- Simple State Interactions
    question: state.question,
    setQuestionResponse: (studyResponse?: StudyQuestion) =>
      setState((_) => ({ ..._, questionResponse: studyResponse })),
    setQuestion: (question: string) => setState((_) => ({ ..._, question })),
    setQuestionId: (questionId: string) => setState((_) => ({ ..._, questionId })),
    qaChatResponse,
    isQAChatLoading,
    questionResponse: state.questionResponse,
    numOfResponses: state.noOfResponses,

    studyIds: state.studyIds,
    setStudyId: (studyId?: string) => setState((_) => ({ ..._, studyIds: studyId ? [studyId] : [] })),

    respondentId: state.respondentId,
    setRespondentId: (respondentId?: string) => setState((_) => ({ ..._, respondentId })),

    messages: state.messages,
    scope: state.scope,
    setScope,

    // -- ReactQuery Managed Variables
    studies,
    isLoadingStudies,

    studyQuestions,
    isLoadingQuestions,

    transcripts,
    isLoadingTranscript,

    toggleStudySummary: () => setState((_) => ({ ..._, showStudySummary: !_.showStudySummary })),
    showStudySummary: state.showStudySummary,
    isStudySummaryLoading,
    studySummaryResponse,

    respondentIdsArray,
    facetsArray,
    facets,
    isLoadingFacets,
    search,
    setFilter,
    hasFilters,
    resetFilters,
    updateRespondentFilterJson,

    //generate Respondent Summary,
    generateRespondentSummary,
    resetRespondentsArray,

    // -- Drawer State + Methods
    drawer,
    toggleFilterDrawer,
    toggleExpanded,
    isExpanded,
    isFacetChecked,

    navigate,
  };
};

export default useMarketResearchStore;
