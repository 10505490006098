import { Tab, Tabs } from "@mui/material";
import { useManageStudiesStore } from "./useManageStudiesStore";

export default function StudiesTab() {
  const { StudyListParams, setListView, getViewRecordCount } = useManageStudiesStore();

  const handleTabChange = (_, value: "OWNER" | "EDITOR") => {
    setListView(value);
  };

  const tabValue = StudyListParams.permission_level;

  return (
    <Tabs indicatorColor="primary" textColor="primary" value={tabValue} onChange={handleTabChange}>
      <Tab
        value="OWNER"
        label={`My Studies` + (getViewRecordCount("OWNER") > 0 ? ` (${getViewRecordCount("OWNER")})` : "")}
      />
      <Tab
        value="EDITOR"
        label={`Other Studies` + (getViewRecordCount("EDITOR") > 0 ? ` (${getViewRecordCount("EDITOR")})` : "")}
      />
    </Tabs>
  );
}
