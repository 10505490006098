import { Box, CircularProgress, Typography } from "@mui/material";
import { useEffect } from "react";
import { TOP_BAR_HEIGHT } from "../../../pages/AppLayout";
import NewStudy from "./NewStudy";
import NextCancelButton from "./NextCancelButton";
import ParentCard from "./ParentCard";
import StepperComponent from "./StepperComponent";
import UploadAudio from "./UploadAudio";
import UploadGuides from "./UploadGuides";
import { useAddStudyStore } from "./useAddStudyStore";

export default function AddStudy() {
  const { currentStep, callGetAddStudyList, resetState } = useAddStudyStore();
  const { isLoading, error } = callGetAddStudyList;

  useEffect(() => {
    return () => {
      resetState();
    };
  }, []);

  return (
    <Box
      sx={{
        marginTop: `${TOP_BAR_HEIGHT}px`,
        paddingX: "10rem",
        paddingY: "2rem",
        width: "100%",
        display: "flex",
        flexDirection: "column",

        height: `calc(100vh - ${TOP_BAR_HEIGHT}px)`,
      }}
    >
      <Box
        sx={{
          flex: "0 0 auto",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "2rem",
          display: "flex",
        }}
      >
        <Typography variant="h5">Add Study</Typography>
        <StepperComponent />
        <NextCancelButton />
      </Box>

      <Box sx={{ width: "100%", display: "flex", justifyContent: "center", mt: "5rem" }}>
        {error ? (
          <Typography variant="h6">{"Something went wrong, please try again later."}</Typography>
        ) : isLoading ? (
          <Box>
            <CircularProgress />
          </Box>
        ) : currentStep === 0 ? (
          <ParentCard header="New Study" children={<NewStudy />} />
        ) : currentStep === 1 ? (
          <ParentCard children={<UploadGuides />} />
        ) : currentStep === 2 ? (
          <ParentCard children={<UploadAudio />} />
        ) : null}
      </Box>
    </Box>
  );
}
