import { ListItemIcon, MenuItem, Typography } from "@mui/material";
import { TMenuButtonType } from "../models";

const CustomMenuItem = ({ label, Icon, iconProps, labelProps }: TMenuButtonType) => {
  return (
    <MenuItem sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      <ListItemIcon>
        <Icon {...iconProps} fontSize="medium" color="primary" />
      </ListItemIcon>
      <Typography {...labelProps}>{label}</Typography>
    </MenuItem>
  );
};

export default CustomMenuItem;
