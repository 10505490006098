import { AddSharp } from "@mui/icons-material";
import { Avatar, AvatarGroup, Box, Button, FormControl, TextField } from "@mui/material";
import { StyledModal } from "../../common";
import AddStudyEditors from "./AddEditorsModal";
import Dropdown from "./DropDown";
import { useAddStudyStore } from "./useAddStudyStore";

export default function NewStudy() {
  const {
    studyType,
    setStudyType,
    vendorName,
    setVendorName,
    studyName,
    setStudyName,
    therapeuticArea,
    setTherapeuticArea,
    brand,
    setBrand,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    vendorArray,
    theraputicArerArray,
    brandArray,
    toggleEditorModal,
    isEditorModalOpen,
    editorArray,
    getInitials,
  } = useAddStudyStore();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "60%",
          height: "auto",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <FormControl
            size="medium"
            variant="outlined"
            fullWidth
            sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "1rem" }}
          >
            <Dropdown
              label="*Study Type"
              array={["Quantitative", "Qualitative"]}
              value={studyType}
              onChange={(value) => {
                setStudyType(value);
              }}
            />
            <Dropdown
              label="*Vendor Name"
              array={vendorArray ?? []}
              value={vendorName}
              onChange={(value) => {
                setVendorName(value);
              }}
            />
            <TextField
              label="*Study Name"
              variant="outlined"
              required
              value={studyName}
              onChange={(e) => setStudyName(e.target.value)}
            />
            <Dropdown
              label="*Therapeutic Area"
              array={theraputicArerArray ?? []}
              value={therapeuticArea}
              onChange={(value) => setTherapeuticArea(value)}
            />
            <Dropdown
              label="*Product | Brand"
              array={brandArray ?? []}
              value={brand}
              onChange={(value) => setBrand(value)}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flex: 1,
                width: "100%",
                gap: "1rem",
              }}
            >
              <TextField
                label="Start Date"
                variant="outlined"
                required
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="date"
                inputProps={{ pattern: "\\d{2}-\\d{2}-\\d{4}", placeholder: "MM-DD-YYYY" }}
                onChange={(e) => setStartDate(e.target.value)}
                value={startDate}
              />
              -
              <TextField
                label="End Date"
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={endDate}
                type="date"
                inputProps={{ pattern: "\\d{2}-\\d{2}-\\d{4}", placeholder: "MM-DD-YYYY" }}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Box>
          </FormControl>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mt: "1rem" }}>
            <AvatarGroup max={5}>
              {editorArray?.map((editor, index) => (
                <Avatar alt={editor.auth_user_id} sx={{ bgcolor: "#003087" }} key={index}>
                  {getInitials(editor.auth_user_id)}
                </Avatar>
              ))}
            </AvatarGroup>
            <Button
              onClick={() => {
                toggleEditorModal(true);
              }}
              variant="text"
              color="primary"
              size="medium"
              startIcon={<AddSharp sx={{ color: "#003087" }} />}
              sx={{ color: "#003087", fontSize: "15px" }}
            >
              MANAGE EDITORS
            </Button>
          </Box>
        </Box>
      </Box>
      <StyledModal
        children={<AddStudyEditors />}
        open={isEditorModalOpen}
        handleModalClose={() => {
          toggleEditorModal(false);
        }}
        title="Manage Study Editors"
      />
    </Box>
  );
}
