import { ArrowForward, FilterList } from "@mui/icons-material";
import { Badge, Box, Button, CircularProgress, InputBase, Paper, Tooltip } from "@mui/material";
import { grey } from "../../../colors";
import EUComplianceText from "../../common/EUComplianceText";

// Simple validation method to block the majority of basic nonsense being submitted to the query enhancer
export const isValidQuestion = (question: string) => {
  const text = String(question);
  const words = text.split(" ");
  return text.length > 8 && words.length > 2 && words.some((_word) => _word.length > 2);
};

export interface QuestionInputProps {
  placeholder?: string;
  loading: boolean;
  question: string;
  filterCount?: number;
  onQuestionInput: (text: string) => void;
  onSubmit: (question: string) => void;
  onFilterClick?: () => void;
}

export default function QuestionInputBox({
  placeholder,
  question,
  loading,
  filterCount = 0,
  onQuestionInput,
  onSubmit,
  onFilterClick,
}: QuestionInputProps) {
  const disableFilters = false;

  return (
    <Box>
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          width: "100%",
          flexDirection: disableFilters ? "row" : "column",
        }}
      >
        <Tooltip
          title={
            question && !isValidQuestion(question) ? "Please enter a valid question with 3 or more words" : undefined
          }
        >
          <InputBase
            sx={{ ml: 1, flex: 1, p: disableFilters ? "" : "0.5rem", mt: 1 }}
            disabled={loading}
            multiline
            placeholder={placeholder || "Select filters to narrow down the data, then ask a question..."}
            value={question}
            onKeyDown={(event) => {
              if (event.key === "Enter" && isValidQuestion(question)) {
                // Block page from reloading on "Enter"
                event.preventDefault();
                onSubmit(question);
              }
            }}
            onChange={(event) => {
              onQuestionInput(event.target.value);
            }}
          />
        </Tooltip>
        <Box display="flex" justifyContent="space-between" alignItems="center" marginLeft="1rem">
          {!disableFilters && (
            <Button size="small" startIcon={<FilterList />} onClick={onFilterClick}>
              Filter
            </Button>
          )}
          {loading ? (
            <CircularProgress sx={{ p: "0.25em", m: "4px" }} />
          ) : (
            <Box display="flex">
              <Button
                disableElevation
                disabled={!question || !isValidQuestion(question)}
                variant="contained"
                aria-label="search"
                sx={{ padding: "8px", margin: "4px", minWidth: "unset", color: grey[700] }}
                onClick={() => onSubmit(question)}
              >
                <ArrowForward />
              </Button>
            </Box>
          )}
        </Box>
      </Paper>
      <EUComplianceText />
    </Box>
  );
}
