import { Add, Close, Remove } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  Drawer,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { TOP_BAR_HEIGHT } from "../../../pages/AppLayout";
import useMarketResearchStore from "../useMarketResearchStore";
import FilterDrawerControls from "./FilterDrawerControls";

export const FILTER_WIDTH = 300;
export default function FilterDrawer() {
  const {
    facetsArray,
    setFilter,
    hasFilters,
    resetFilters,
    isLoadingFacets,
    toggleExpanded,
    isExpanded,
    isFacetChecked,
    drawer,
    toggleFilterDrawer,
  } = useMarketResearchStore();

  const showFilters = drawer.open;
  const onCloseClick = () => toggleFilterDrawer();

  return (
    <Drawer
      open={showFilters}
      sx={{
        width: showFilters ? FILTER_WIDTH : 0,
        "& .MuiDrawer-paper": {
          width: FILTER_WIDTH,
          boxSizing: "border-box",
          borderRight: "none",
        },
      }}
      variant="persistent"
      anchor="left"
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          marginTop: `${TOP_BAR_HEIGHT}px`,
          padding: "10px",
        }}
      >
        <Close onClick={onCloseClick} sx={{ position: "absolute", right: 20 }} />
        <Divider sx={{ marginTop: "40px" }} />

        {isLoadingFacets ? (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
            <CircularProgress />
          </Box>
        ) : (
          facetsArray?.map((filter, i) => (
            <Box key={i}>
              <Accordion
                sx={{
                  background: "transparent",
                  boxShadow: "none",
                  "&:before": {
                    display: "none",
                  },
                }}
                elevation={0}
                expanded={isExpanded(filter.column_name)}
                onChange={() => toggleExpanded(filter.column_name)}
              >
                <AccordionSummary
                  expandIcon={isExpanded(filter.column_name) ? <Remove /> : <Add />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography>{filter.filter_name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {(filter.filter_values ?? []).map((value, j) => (
                    <Box
                      key={j}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            checked={isFacetChecked(filter.column_name, value)}
                            onChange={(event) => {
                              setFilter(filter.column_name, value, event.target.checked);
                            }}
                          />
                        }
                        label={
                          <Typography variant="body2" color="textSecondary">
                            {value}
                          </Typography>
                        }
                      />
                      <Typography variant="body2" color="textSecondary" sx={{ display: "none" }}>
                        (2)
                      </Typography>
                    </Box>
                  ))}
                </AccordionDetails>
              </Accordion>
              <Divider />
            </Box>
          ))
        )}
      </Box>

      <FilterDrawerControls hasActiveFilters={hasFilters()} onClickReset={() => resetFilters()} />
    </Drawer>
  );
}
