import { Box, Card, Divider, Typography } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { useAddStudyStore } from "./useAddStudyStore";

type TDisplayItems = {
  header?: string;
  children: React.ReactNode;
};

export default function ParentCard({ header, children }: TDisplayItems) {
  const { vendorName, studyName, startDate, currentStep } = useAddStudyStore();
  return (
    <Card
      sx={{
        width: "50rem",
        padding: "1rem",
        borderRadius: "10px",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="h6" sx={{ fontSize: "20px", color: "#13294B", fontWeight: "700" }}>
          {header ? header : vendorName + " | " + studyName}
        </Typography>
        {currentStep != 0 && (
          <Typography variant="h6" sx={{ fontSize: "20px", color: "#13294B", fontWeight: "700" }}>
            {dayjs(startDate).format("MM-DD-YYYY")}
          </Typography>
        )}
      </Box>
      <Divider sx={{ margin: "0.5rem 0" }} />
      <Box sx={{ display: "flex", flexDirection: "column", py: "1rem" }}>{children}</Box>
    </Card>
  );
}
