import { DeleteSharp, FileDownloadOutlined, RepeatSharp, ReplaySharp } from "@mui/icons-material";
import { Divider, Menu } from "@mui/material";
import { TAudioMenuType } from "../models";
import CustomMenuItem from "./CustomMenuItem";

const AudioMenu = ({ anchorEl, open, handleClose }: TAudioMenuType) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      slotProps={{ paper: { sx: { width: "160px", px: "5px", display: "flex", flexDirection: "row" } } }}
    >
      <CustomMenuItem label="Download" Icon={FileDownloadOutlined} labelProps={{ sx: { color: "#003087" } }} />
      <CustomMenuItem label="Rerun" Icon={ReplaySharp} labelProps={{ sx: { color: "#003087" } }} />
      <Divider />
      <CustomMenuItem
        label="Replace"
        Icon={RepeatSharp}
        labelProps={{ sx: { color: "red" } }}
        iconProps={{ sx: { color: "red" } }}
      />
      <CustomMenuItem
        label="Delete"
        Icon={DeleteSharp}
        labelProps={{ sx: { color: "red" } }}
        iconProps={{ sx: { color: "red" } }}
      />
    </Menu>
  );
};

export default AudioMenu;
