import { Autocomplete, Box, FormControl, TextField, Typography } from "@mui/material";
import { TDropdownComponent } from "../models";

const Dropdown = ({ label, array, value, onChange }: TDropdownComponent) => {
  return (
    <FormControl variant="outlined" fullWidth>
      <Autocomplete
        options={array}
        value={value}
        getOptionLabel={(option) => `${option}`}
        renderOption={(props, option) => {
          const { key, ...prop } = props;
          return (
            <Box component="li" {...prop} sx={{ display: "flex", alignItems: "center", gap: 1 }} key={key}>
              <Box>
                <Typography variant="body1">{option}</Typography>
              </Box>
            </Box>
          );
        }}
        filterSelectedOptions
        renderInput={(params) => <TextField {...params} label={label} />}
        onChange={(_, value) => {
          onChange(value);
        }}
      />
    </FormControl>
  );
};

export default Dropdown;
