import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useEffect } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

import { AddSharp, Settings } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDeepChatSocketContext } from "../../../contexts/DeepChatSocketContext";
import { BodyPanel, MainPanel, RightPanel } from "../Layout/Panels";
import useMarketResearchStore from "../useMarketResearchStore";
import QuestionInputBox from "../ViewStudy/QuestionInputBox";
import StudyTile from "./StudyTile";
import useFeatureFlag from "../../../featureFlags/useFeatureFlag";
import { Feature } from "../../../featureFlags/featureFlagConfig";

export default function AllStudies() {
  const ChatAcrossStudiesFeature = useFeatureFlag(Feature.ChatAcrossStudies);
  const navigate = useNavigate();
  const DeepChatSocket = useDeepChatSocketContext();

  const {
    isLoadingStudies,
    studies,
    setStudyId,
    setQuestion,
    question,
    askQuestion,
    toggleLoadingChat,
    isLoadingChat,
    resetSocket,
    clearChatSession,
    toggleFilterDrawer,
    setScope,
    filterCount,
  } = useMarketResearchStore();

  useEffect(() => {
    if (studies?.length) {
      setStudyId();
    }

    if (isLoadingChat) {
      resetSocket();
    }

    setScope("list");
    // This can be switched to `true` after ChatAcrossStudies gets released to Prod
    toggleFilterDrawer(ChatAcrossStudiesFeature.enabled);
  }, []);

  const askDeepchatQuestion = (question: string) => {
    clearChatSession();
    toggleLoadingChat(true);
    toggleFilterDrawer(false);
    askQuestion(
      DeepChatSocket.sendJsonMessage,
      question,
      (studies ?? []).map((_) => _.study_key),
    );
    navigate(`/market-research/threads/`);
  };

  return (
    <MainPanel>
      <BodyPanel sx={{ minWidth: "325px" }}>
        <Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "2rem" }}>
            <Typography variant="h5">All Studies</Typography>
            <Button
              onClick={() => {
                toggleFilterDrawer(false);
                navigate("/market-research/manage-studies");
              }}
              variant="text"
              color="primary"
              size="medium"
              startIcon={<Settings fontSize="small" sx={{ color: "#003087" }} />}
              sx={{
                color: "#003087",
                padding: "0.5em",
                minWidth: "unset",
                "& .MuiButton-startIcon": { marginRight: "unset" },
              }}
            ></Button>
          </Box>

          {ChatAcrossStudiesFeature.enabled && (
            <>
              <Box sx={{ my: "2em" }}>
                <QuestionInputBox
                  loading={false}
                  onQuestionInput={(text: string) => {
                    setQuestion(text);
                  }}
                  filterCount={filterCount()}
                  onFilterClick={() => toggleFilterDrawer()}
                  onSubmit={() => askDeepchatQuestion(question)}
                  question={question}
                  placeholder="Ask Deepsense..."
                />
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", my: "1rem" }}>
                <Typography variant="h5" sx={{ fontSize: "20px" }}>
                  Explore an individual study:
                </Typography>
              </Box>
            </>
          )}

          {isLoadingStudies ? (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80%" }}>
              <CircularProgress />
            </Box>
          ) : (
            <ResponsiveMasonry columnsCountBreakPoints={{ 550: 1, 1350: 2, 1600: 3, 2000: 4 }}>
              <Masonry gutter="15px">
                {(studies ?? []).map((study) => {
                  return <StudyTile study={study} key={study.study_key} />;
                })}
              </Masonry>
            </ResponsiveMasonry>
          )}
        </Box>
      </BodyPanel>

      <RightPanel sx={{ display: "flex", flexDirection: "column" }}>
        <></>
      </RightPanel>
    </MainPanel>
  );
}
