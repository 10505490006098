import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { SyntheticEvent, useEffect, useState } from "react";
import { TEditorsListResponse } from "../models";
import { useAddStudyStore } from "./useAddStudyStore";

const AddStudyEditors = () => {
  const [selectedUsers, setSelectedUsers] = useState<TEditorsListResponse[]>([]);
  const { setEditorsArray, getInitials, toggleEditorModal, editorsList, isEditorsLoading, editorArray } =
    useAddStudyStore();

  useEffect(() => {
    setSelectedUsers(editorArray ?? []);
  }, []);

  if (isEditorsLoading) {
    return (
      <Box sx={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
        <CircularProgress />
      </Box>
    );
  }

  function handleChange(
    event: SyntheticEvent<Element, Event>,
    value: TEditorsListResponse[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<TEditorsListResponse> | undefined,
  ): void {
    if (reason === "clear") {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(value);
    }
  }

  return (
    <Box sx={{ p: 2 }}>
      <Autocomplete
        multiple
        value={selectedUsers}
        options={editorsList ?? []}
        getOptionLabel={(option) => `${option.auth_user_id} (${option.auth_user_email})`}
        renderOption={(props, option) => {
          const { key, ...prop } = props;
          return (
            <Box component="li" {...prop} sx={{ display: "flex", alignItems: "center", gap: 1 }} key={key}>
              <Avatar sx={{ bgcolor: "#1976d2" }}>{getInitials(option.auth_user_id)}</Avatar>
              <Box>
                <Typography variant="body1">{option.auth_user_id}</Typography>
                <Typography variant="body2" color="text.secondary">
                  {option.auth_user_email}
                </Typography>
              </Box>
            </Box>
          );
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            const { key, ...tagProps } = getTagProps({ index });
            return (
              <Chip
                key={option.user_guid}
                size="small"
                avatar={
                  <Avatar
                    sizes="small"
                    sx={{
                      bgcolor: "#004677",
                      color: "#fff !important",
                    }}
                  >
                    {getInitials(option.auth_user_id)}
                  </Avatar>
                }
                label={option.auth_user_id}
                {...tagProps}
              />
            );
          })
        }
        onChange={handleChange}
        filterSelectedOptions
        renderInput={(params) => <TextField {...params} label="Search Users" />}
      />

      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
        <Button
          onClick={() => {
            toggleEditorModal(false);
            setEditorsArray(selectedUsers);
          }}
          variant="contained"
          color="primary"
          disabled={editorArray?.length === 0 ? selectedUsers.length === 0 : false}
          sx={{ backgroundColor: "#003087" }}
        >
          SAVE
        </Button>
      </Box>
    </Box>
  );
};

export default AddStudyEditors;
