import { ArrowForward } from "@mui/icons-material";
import { Box, Button, InputBase, Tooltip, Typography } from "@mui/material";
import { UUID } from "crypto";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AutoSizer } from "react-virtualized";

import { useDeepChatSocketContext } from "../../../contexts/DeepChatSocketContext";
import EUComplianceText from "../../common/EUComplianceText";
import { BodyPanel, MainPanel, RightPanel } from "../Layout/Panels";
import useMarketResearchStore from "../useMarketResearchStore";
import ChatAnswerNode from "./ChatAnswerNode";
import ChatSessionOutline from "./ChatSessionOutline";

export const isValidQuestion = (question: string) => {
  const text = String(question);
  const words = text.split(" ");
  return text.length > 8 && words.length > 2 && words.some((_word) => _word.length > 2);
};

const getThreadId = (value: string) => {
  return value ? (value as UUID) : undefined;
};

export default function StudyChat() {
  const location = useLocation();
  const navigate = useNavigate();
  const DeepChatSocket = useDeepChatSocketContext();
  const {
    scope,
    messages,
    question,
    invocationType,
    setQuestion,
    askQuestion,
    studyIds,
    getStudy,
    clearChatSession,
    isLoadingChat,
    socketStatusMessage,
    numOfResponses,
    resetSocket,
  } = useMarketResearchStore();

  const startNewThread = () => {
    resetSocket();
    clearChatSession();
    navigate(-1);
  };

  // TODO: This needs to be an array
  const handleAskQuestion = (question: string, study_keys?: string[]) => {
    if (question.length && !isLoadingChat) {
      askQuestion(DeepChatSocket.sendJsonMessage, question, study_keys ?? []);
      setQuestion("");
    }
  };

  const handleChangeStudy = () => {
    clearChatSession();
    navigate("/market-research");
  };

  // Sync the session key once we get it from the current chat interaction
  // This may be overkill for trying to inject the UUID into the URI on page transition to this view
  useEffect(() => {
    const message = messages.at(-1);
    const path = location.pathname.split("/");
    const threadId = getThreadId(path.at(-1) ?? "");

    if (message && threadId != message.user_chat_session) {
      navigate(`/market-research/threads/${message.user_chat_session}`, {
        replace: true,
        state: { prevent_load: true },
      });
    }
  }, [messages, location]);

  // Scroll the title of the most recent question up into view when the session[] updates
  useEffect(() => {
    const target = messages.at(-1);
    if (target) {
      const anchorId = `${target?.user_chat_session}-${messages.indexOf(target)}`;
      scrollQuestionIntoView(anchorId);
    }
  }, [messages]);

  // Try to get study details by its "study_key"
  const study = studyIds.length == 1 ? getStudy(studyIds.at(0)!) : undefined;

  return (
    <MainPanel>
      <BodyPanel>
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", paddingBottom: "32px", padding: "2px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
            flexDirection={"row"}
          >
            <Typography variant="h5" sx={{ color: "#004677" }}>
              {study?.study_title}
            </Typography>

            {scope == "chat" && (
              <Link
                to={"/market-research"}
                onClick={(_) => {
                  _.preventDefault();
                  handleChangeStudy();
                }}
                style={{ textDecoration: "none", color: "#003087" }}
              >
                <Button color="primary" sx={{ color: "#003087" }}>
                  CHANGE STUDY
                </Button>
              </Link>
            )}
          </Box>

          {messages.map((interaction, i) => {
            const anchorId = `${String(interaction.user_chat_session)}-${i}`;
            const isLastItem = messages.at(-1) === interaction;
            return (
              <ChatAnswerNode
                key={i}
                anchorId={anchorId}
                interaction={interaction}
                isLoading={isLastItem && isLoadingChat}
                loadingStatus={socketStatusMessage}
                numOfResponses={numOfResponses}
              />
            );
          })}
        </Box>

        {/* Followup Question Input */}
        <Box sx={{ height: "84px" }} className="followup-input-base">
          <AutoSizer disableHeight>
            {({ width }) => (
              <Box
                sx={{
                  position: "fixed",
                  width: width,
                  bottom: 0,
                  backgroundImage: "linear-gradient(to bottom, rgba(245,245,249,0) 0%, rgba(245,245,249,1) 25%)",
                  py: "2em",
                  // border: "1px solid blue",
                }}
              >
                <Box
                  sx={{
                    padding: "10px",
                    display: invocationType == "qna" ? "none" : "flex",
                    backgroundColor: "white",
                  }}
                  border={1}
                  borderRadius={5}
                  borderColor={"#E0E0E0"}
                >
                  <Tooltip
                    title={
                      question && !isValidQuestion(question)
                        ? "Please enter a valid question with 3 or more words"
                        : undefined
                    }
                  >
                    <InputBase
                      sx={{ width: "100%" }}
                      multiline
                      value={question}
                      disabled={isLoadingChat}
                      onKeyDown={(event) => {
                        if (event.key === "Enter" && isValidQuestion(question)) {
                          event.preventDefault();
                          handleAskQuestion(question, studyIds);
                        }
                      }}
                      onChange={(event) => {
                        setQuestion(event.target.value);
                      }}
                    />
                  </Tooltip>
                  <Button
                    disableElevation
                    variant="contained"
                    aria-label="search"
                    disabled={!question || !isValidQuestion(question) || isLoadingChat}
                    onClick={() => handleAskQuestion(question, studyIds)}
                    sx={{
                      padding: "8px",
                      margin: "4px",
                      minWidth: "unset",
                      backgroundColor: question.length === 0 ? "#E0E0E0" : "#023088",
                      color: question.length === 0 ? "#E0E0E0" : "white",
                    }}
                  >
                    <ArrowForward />
                  </Button>
                </Box>
                <EUComplianceText />
              </Box>
            )}
          </AutoSizer>
        </Box>
      </BodyPanel>

      <RightPanel>
        <ChatSessionOutline
          messages={messages}
          onClickNewThread={() => startNewThread()}
          invocationType={invocationType}
        />
      </RightPanel>
    </MainPanel>
  );
}

export const scrollQuestionIntoView = (anchorId: string) => {
  const element = document.getElementById(anchorId);
  if (element) {
    const y = element.getBoundingClientRect().top + window.scrollY - (48 + 24); // Menu + 1em padding
    window.scrollTo({ top: y, behavior: "smooth" });
  }
};
