import { ArrowDropDown } from "@mui/icons-material";
import { Button, ButtonGroup, CircularProgress, Divider, Menu, MenuItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { TCompleteSaveButtonType } from "../models";
import { useAddStudyStore } from "./useAddStudyStore";

const CompleteMenu = ({
  anchorEl,
  open,
  isSaveDisabled,
  isCompleteDisabled,
  handleClose,
  handleSaveOnlyClick,
  handleCompleteClick,
}: TCompleteSaveButtonType) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      slotProps={{
        paper: {
          sx: { width: "310px", display: "flex", flexDirection: "column" },
        },
      }}
    >
      <MenuItem disabled={isCompleteDisabled} onClick={handleCompleteClick}>
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "0.2rem" }}>
          <Typography sx={{ fontSize: "14px" }}>Complete</Typography>
          <Typography sx={{ fontSize: "10px", whiteSpace: "normal", wordWrap: "break-word" }}>
            Process all files, create study summary, and make this study available to Deepsense within 24 hours. (You
            can still upload additional files and update the study at a later date.)
          </Typography>
        </Box>
      </MenuItem>
      <Divider />
      <MenuItem disabled={isSaveDisabled} onClick={handleSaveOnlyClick}>
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "0.2rem" }}>
          <Typography sx={{ fontSize: "14px" }}>Save Only</Typography>
          <Typography sx={{ fontSize: "10px", whiteSpace: "normal", wordWrap: "break-word" }}>
            Transcribe available audio files, but don’t generate the study summary or make this study available to
            Deepsense yet. (Choose this if additional files are coming shortly.)
          </Typography>
        </Box>
      </MenuItem>
    </Menu>
  );
};

export default function NextCancelButton() {
  const {
    navigate,
    currentStep,
    isNextDisabled,
    isLoading,
    uploadGuidesToS3,
    uploadAudioFilesToS3,
    addStudy,
    resetState,
    completeStudy,
    screenerSummaryFile,
    screenerGuideFile,
    discussionGuideFile,
  } = useAddStudyStore();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCancel = () => {
    resetState();
    navigate(-1);
  };

  const isFromScreenerSummary = () => {
    if (!screenerSummaryFile) {
      return false;
    }
    return true;
  };

  const handleNext = () => {
    if (currentStep === 0) {
      addStudy();
    } else if (currentStep === 1) {
      uploadGuidesToS3();
    } else {
      if (!isFromScreenerSummary()) {
        completeStudy();
      } else {
        uploadAudioFilesToS3();
      }
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
      <Button
        variant="outlined"
        color="primary"
        size="large"
        onClick={handleCancel}
        sx={{ color: "#003087", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        size="large"
        color="primary"
        disabled={isNextDisabled || isLoading}
        onClick={handleNext}
        sx={{
          color: "white",
          width: "100%",
          display: currentStep !== 2 ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#003087",
          "&:hover": {
            color: "black",
          },
        }}
      >
        {isLoading ? <CircularProgress size={24} sx={{ color: "white" }} /> : currentStep === 2 ? "Save" : "Next"}
      </Button>
      <ButtonGroup
        color="inherit"
        orientation="horizontal"
        variant="contained"
        sx={{
          justifyItems: "center",
          alignItems: "center",
          backgroundColor: "#003087",
          height: "46px",
          display: currentStep === 2 ? "flex" : "none",
        }}
      >
        <Box sx={{ display: "flex", px: "20px", py: "5px", gap: 1, borderRight: 1, borderColor: "#e5e5ef" }}>
          <Typography sx={{ fontSize: "14px", fontWeight: "400", color: "white" }}>COMPLETE</Typography>
        </Box>
        <Button
          variant="text"
          color="primary"
          size="medium"
          sx={{ height: "46px", color: "white" }}
          id="basic-button"
          aria-controls={open ? "grouped-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          {isLoading ? <CircularProgress size={24} sx={{ color: "white" }} /> : <ArrowDropDown />}
        </Button>
      </ButtonGroup>
      <CompleteMenu
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        isSaveDisabled={isFromScreenerSummary() ? isNextDisabled || isLoading : true}
        isCompleteDisabled={
          isLoading
            ? true
            : isFromScreenerSummary()
              ? isNextDisabled || screenerGuideFile === null || discussionGuideFile === null
              : false
        }
        handleSaveOnlyClick={handleNext}
        handleCompleteClick={completeStudy}
      />
    </Box>
  );
}
