import { Box, BoxProps } from "@mui/material";
import { TOP_BAR_HEIGHT } from "../../../pages/AppLayout";

export function MainPanel({ children, ...props }: BoxProps) {
  return (
    <Box
      style={{
        padding: "0px",
        marginTop: `${TOP_BAR_HEIGHT}px`,
        minHeight: `calc(100vh - ${TOP_BAR_HEIGHT}px)`,
        display: "flex",
        background: "#F7F8F8",
      }}
      sx={props.sx}
    >
      {children}
    </Box>
  );
}

export function BodyPanel({ children, ...props }: BoxProps) {
  return (
    <Box
      style={{
        flex: 1,
        padding: "2em",
      }}
      {...props}
    >
      {children}
    </Box>
  );
}

export function RightPanel({ children, ...props }: BoxProps) {
  return (
    <Box {...props}>
      <Box sx={{ width: "380px" }}></Box>
      <Box
        sx={{
          width: "380px",
          ml: "2em",
          p: "2em",
          borderLeft: "1px solid #eee",
          position: "fixed",
          right: 0,
        }}
      >
        <Box sx={{ display: "flex", gap: "1em", flexDirection: "column", height: "calc(100vh - 74px - 2em)" }}>
          {children}
        </Box>
      </Box>
    </Box>
  );
}
