import { useCallback, useEffect, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import * as DeepchatApi from "../../api/deepchat";
import { WebSocketChatResponse } from "../../contexts/useDeepchat";

import useUserContext from "../../components/Authorization/useUserContext";
import { atom, useAtom } from "jotai";

const DEEPCHAT_WS_URI = import.meta.env.VITE_APP_DEEPCHAT_WS_URI;

// Moving reset trigger for socket connection to an Atom so that useMarketResearchStore() can access it in the contexts it needs it
export const MarketResearchSocketThreadAtom = atom<number>(0);

export default function useMarketResearchSocket() {
  const user = useUserContext();
  const [socketThread, setSocketThread] = useAtom(MarketResearchSocketThreadAtom);

  // Reference Implementation
  // const resetSocket = () => {
  //   setSocketThread((_) => _ + 1);
  // };

  const formatChatUrl = (chatAuthCode: string) => {
    return `${DEEPCHAT_WS_URI}?authCode=${chatAuthCode}`;
  };

  const getChartUrl = useCallback(async () => {
    const response = await DeepchatApi.getToken(user);
    return formatChatUrl(response.auth_code);
  }, [socketThread]);

  const getWebSocket = useCallback(
    () =>
      useWebSocket<WebSocketChatResponse<unknown>>(getChartUrl, {
        shouldReconnect: () => true,
      }),
    [socketThread],
  );
  const { lastJsonMessage, sendJsonMessage, readyState } = getWebSocket();

  return {
    lastJsonMessage,
    sendJsonMessage,
    readyState,
  };
}
