import { ArrowDropDown, DeleteSharp, SpatialAudioOffOutlined } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Input, Typography } from "@mui/material";
import { useRef, useState } from "react";
import emitSnackbar from "../../../emitSnackbar";
import { useAddStudyStore } from "../AddStudy/useAddStudyStore";
import { TUploadDocs } from "../models";
import AudioMenu from "./AudioMenu";
import CustomButtonGroup from "./CustomButtonGroup";
import StatusIndicator from "./StatusIndicator";

export default function EditAudioFileUpload({
  id,
  header,
  respondentId,
  registerAudioFile,
  acceptedFiles,
}: TUploadDocs) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { vendorName, studyName, startDate, sanitizeString, checkForDuplicate, getFileExtension } = useAddStudyStore();

  const handleDelete = () => {
    setFile(null);
    registerAudioFile(null);
  };
  const handleUpload = () => {
    inputRef.current?.click();
  };

  const handleFileUpload = (file?: File) => {
    if (file && !checkForDuplicate(file)) {
      setFile(file);
      registerAudioFile(file);
    } else {
      emitSnackbar(
        `This file ${file?.name} has been uploaded more than once. Please review uploaded files and make the necessary changes.`,
        "info",
        { autoHideDuration: 30000 },
      );
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const droppedFiles = e.dataTransfer.files;
    const file = droppedFiles[0];
    const ext = getFileExtension(file.name);

    switch (id) {
      case 0:
        if (ext !== "csv" && ext !== "xml") {
          emitSnackbar("Only .csv and .xml files are allowed", "error", { autoHideDuration: 10000 });
          return;
        }
        break;
      case 1:
      case 2:
        if (ext !== "pdf" && ext !== "doc" && ext !== "docx") {
          emitSnackbar("Only .pdf, .doc and .docx files are allowed", "error", { autoHideDuration: 10000 });
          return;
        }
        break;
      case 3:
        if (ext !== "ppt" && ext !== "pptx") {
          emitSnackbar("Only .ppt and .pptx files are allowed", "error", { autoHideDuration: 10000 });
          return;
        }
        break;
    }

    handleFileUpload(file);
  };

  return (
    <Box key={header}>
      <Typography sx={{ fontSize: "12px", fontWeight: "400", color: "#13294B" }}>Audio Transcripts</Typography>
      {/* <Typography variant="h6" sx={{ fontSize: "12px", fontWeight: "400" }}>
        {header}
      </Typography> */}
      <Box
        sx={{
          width: "100%",
          borderWidth: file ? "0px" : "1px",
          borderStyle: "dashed",
          borderColor: "#BDBDBD",
          mt: "0.5rem",
          borderRadius: "5px",
          display: "flex",
          padding: "0.5rem",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <Box
            sx={{
              width: "3rem",
              height: "3rem",
              background: file ? " #00A3E0" : " rgba(0, 163, 224, 0.3)",
              display: "flex",
              borderRadius: "5px",
              justifyContent: "center",
              alignItems: "center",
              flexShrink: "0",
            }}
          >
            <SpatialAudioOffOutlined sx={{ width: "30px", height: "30px", color: "white" }} fontSize="medium" />
          </Box>
          {respondentId && !file && (
            <Typography variant="h6" sx={{ fontSize: "16px", fontWeight: "400", color: "#13294B" }}>
              Respondent Id: {respondentId}
            </Typography>
          )}
        </Box>
        {file ? (
          <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
            <Box sx={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}>
              <Typography variant="h6" sx={{ fontSize: "16px", fontWeight: "400", color: "13294B" }}>
                {sanitizeString(vendorName)}_{sanitizeString(studyName)}_{sanitizeString("audio_transcript")}_
                {sanitizeString(respondentId ?? "")}_{sanitizeString(startDate)}
              </Typography>
              <Typography variant="h6" sx={{ fontSize: "12px", fontWeight: "400", color: "13294B" }}>
                Original file name:
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: "1rem", alignItems: "center", justifyContent: "center" }}>
              <StatusIndicator status="Complete" />
              <CustomButtonGroup label="TEXT" Icon={DeleteSharp} handleOnClick={() => {}} />
              <ButtonGroup
                color="inherit"
                orientation="horizontal"
                variant="contained"
                sx={{ justifyItems: "center", alignItems: "center", backgroundColor: "#f7f8f8", height: "34px" }}
              >
                <Box sx={{ display: "flex", px: "10px", py: "5px", gap: 1, borderRight: 1, borderColor: "#e5e5ef" }}>
                  <SpatialAudioOffOutlined sx={{ fontSize: "20px", color: "#003087" }} />
                  <Typography sx={{ fontSize: "14px", fontWeight: "400", color: "#13294B" }}>AUDIO</Typography>
                </Box>
                <Button
                  variant="text"
                  color="primary"
                  size="medium"
                  sx={{ color: "#003087", height: "40px" }}
                  id="basic-button"
                  aria-controls={open ? "grouped-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <ArrowDropDown />
                </Button>
              </ButtonGroup>
              <AudioMenu anchorEl={anchorEl} open={open} handleClose={handleClose} />
            </Box>
          </Box>
        ) : (
          <Box
            id={header}
            key={header}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              gap: "10px",
            }}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <Typography variant="h6" sx={{ fontSize: "16px", fontWeight: "400" }}>
              Drag and drop files or
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              onClick={handleUpload}
              sx={{ border: "1px solid rgba(0, 48, 135, 0.5)", color: " #003087" }}
            >
              Browse...
              <Input
                type="file"
                inputProps={{ accept: acceptedFiles }}
                inputRef={inputRef}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileUpload(event.target.files?.[0])}
                style={{ display: "none" }} // Hide the input element
              />
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
}
