import { Box, Step, StepLabel, Stepper } from "@mui/material";
import { useAddStudyStore } from "./useAddStudyStore";

export default function StepperComponent() {
  const { stepsArray, currentStep } = useAddStudyStore();
  return (
    <Box sx={{ width: "40%", marginLeft: "5rem" }}>
      <Stepper activeStep={currentStep} alternativeLabel>
        {stepsArray.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
